import {Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import {FormGroup, FormControl} from '@angular/forms';

@Component({
  selector: 'pilot-spec-group',
  template: ''
})
export class SpecGroupComponent implements OnInit, OnChanges {

  @Input() public form: FormGroup;
  @Input() public values: any;
  public keys: Array<string> = [];

  ngOnInit(): void {
    this.keys.forEach(k => {
      this.form.addControl(k, new FormControl(''));
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (Object.keys(changes['values'].currentValue).length) {
      this.initExistingValues();
    }
  }

  private initExistingValues(): void {
    this.keys.forEach(k => {
      const formControl = this.form.get(k);
      if (formControl) {
        formControl.setValue((this.values[k] || (k !== 'other' ? 0 : '')).toString());
      }
    });
  }
}
