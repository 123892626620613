import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'visitor-label-value',
  templateUrl: './label-value.component.html'
})
export class LabelValueComponent implements OnInit {

  @Input() label: string;
  @Input() value: string;

  constructor() { }

  ngOnInit() {
  }

}
