import {Component, OnInit, NgZone, Inject} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

import {Broadcast} from '../../../../common/services/broadcast';
import {ChangePasswordServiceInterface} from '../../../../common/interfaces/service';
import {environment} from '../../../../../environments/environment';
import {SaveButton} from '../../../../common/services/savebutton';
import {Validator} from '../../../../common/services/validator';

@Component({
  selector: 'base-change-password',
  template: ''
})
export class BaseChangePasswordComponent implements OnInit {

  private validationParams = environment.apiValidationParams;
  public button: SaveButton = new SaveButton();
  public changePassForm: FormGroup;
  public serverError: string;
  public submitted: boolean;

  constructor(@Inject('ChangePasswordServiceInterface') protected service: ChangePasswordServiceInterface,
  protected zone: NgZone,
  protected broadcast: Broadcast) {
    this.button.broadcaster(broadcast);
  }

  ngOnInit(): void {
    this.changePassForm = new FormGroup({
      old_password: new FormControl('', [Validators.required, Validators.minLength(this.validationParams.password.minlength)]),
      new_password: new FormControl('', [Validators.required, Validators.minLength(this.validationParams.password.minlength)]),
      confirmPassword: new FormControl('', [Validators.required])
    });
    this.changePassForm.get('confirmPassword').setValidators(Validator.matchTo.bind(this.changePassForm.get('new_password')));
  }

  public onSubmit(form: FormGroup) {
    if (this.serverError) {
      this.serverError = null;
    }
    this.submitted = true;
    if (form.valid) {
      this.button.turnToRequest();
      const payload = this.changePassForm.getRawValue();
      delete payload.confirmPassword;
      this.service.changePassword(payload).subscribe(res => {
        this.button.receiveResponse();
      }, e => {
        this.zone.run(() => {
          this.button.receiveResponse(true);
          this.serverError = e.error.message;
        });
      });
    }
  }
}
