
export const commonEnvironment = {
  endpoints: {
    /* todo wrap this into the pilot object */
    activities: '/pilots/activities',
    avatarUpload: '/pilots/upload/resource/avatar',
    changePassword: '/pilots/pilots/change-password-account',
    completeProfActions: '/pilots/pilots/complete-profile-actions',
    contactRequest: '/partners/webhooks/contact-us',
    createOrder: '/partners/order-funnel/request',
    deleteAccount: '/pilots/pilots/delete-account',
    deleteEquipmentPackage: '/pilots/equipment-packages',
    equipmentCameras: '/pilots/equipment-drones',
    equipmentDrones: '/pilots/equipment-manufacturers/drones',
    equipmentDronesAcc: '/pilots/equipment-drones',
    equipmentManufacturers: '/pilots/equipment-manufacturers',
    equipmentPackages: '/pilots/equipment-packages',
    externalOrder: '/partners/webhooks/real-estate?access-token=Ls8PwrCSKr9syaMcqpryCQ9zmLWOTGPj',
    getAllIndustries: '/guests/industry/show',
    getCountries: '/pilots/documents/countries',
    getFunnelQuestions: '/partners/order-funnel/questions',
    getJobById: '/pilots/orders',
    getPilotProfile: '/pilots/pilots/profile',
    getPrevJobById: '/pilots/orders/previous',
    services: '/partners/services/load',
    // homedayExternalOrder: '/partners/webhooks/homeday-external-order?access-token=Ls8PwrCSKr9syaMcqpryCQ9zmLWOTGPj',
    // immoviewerErfassung: '/partners/webhooks/immoviewer-erfassung?access-token=Ls8PwrCSKr9syaMcqpryCQ9zmLWOTGPj',
    mapSelection: '/partners/webhooks/map-selection',
    notificationsSettings: '/pilots/pilots/notification-subscriptions',
    quoteRequest: '/pilots/orders',
    quoteRequestById: '/pilots/orders/request',
    quoteRequests: '/pilots/orders/quote-requests',
    recentLogins: '/pilots/pilots/recent-logins',
    register: '/pilots/pilots',
    // solyticExternalOrder: '/partners/webhooks/solytic-external-order?access-token=Ls8PwrCSKr9syaMcqpryCQ9zmLWOTGPj',
    staticPageLoad: '/partners/static-pages/load',
    updateProfile: '/pilots/pilots',
    uploadRefImage: '/partners/order-funnel/upload',

    /* client endpoints */
    client: {
      activeJobs: '/clients/orders/active',
      applyInvitation: '/clients/clients/set-password',
      changePassword: '/clients/clients/change-password-account',
      chooseOffer: '/clients/orders',
      completeProfActions: '/clients/clients/complete-profile-actions',
      conversations: '/clients/messages/conversations',
      deleteAccount: '/clients/clients/delete-account',
      getConversationOfOrder: '/clients/messages/order-conversation',
      getInviteInfo: '/clients/clients/confirm',
      getJobById: '/clients/orders',
      getProfile: '/clients/clients/profile',
      markOrderAsDone: '/clients/orders',
      newPassRequest: '/clients/clients/reset-password',
      notificationsSettings: '/clients/clients/notification-subscriptions',
      orders: '/clients/orders',
      orderOffers: '/clients/orders',
      postMessageToOrderConversation: '/clients/messages/conversations',
      previousJobs: '/clients/orders/previous',
      profile: '/clients/clients/profile',
      recentActivities: '/clients/activities',
      recentLogins: '/clients/clients/recent-logins',
      register: '/clients/clients',
      updateProfile: '/clients/clients',
      uploadDocs: '/clients/orders',
      uploadOrderDocuments: '/clients/orders',
      uploadRefImage: '/partners/order-funnel/upload',
      verifyOrder: '/clients/orders',
      verify: {
        sms: '/clients/clients/verify-phone',
        token: '/clients/clients/verify-phone-token'
      },
    },
    pilot: {
      activeJobs: '/pilots/orders/active',
      conversations: '/pilots/messages/conversations',
      getConversationOfOrder: '/pilots/messages/order-conversation',
      getResource: '/pilots/resources',
      insuranceUpload: '/pilots/documents/insurance',
      markOrderAsDone: '/pilots/orders',
      newPassRequest: '/pilots/pilots/reset-password',
      permitUpload: '/pilots/documents/license',
      portfolioUpload: '/pilots/upload/resource/portfolio',
      postMessageToOrderConversation: '/pilots/messages/conversations',
      previousJobs: '/pilots/orders/previous',
      permission: '/pilots/orders/permission',
      profile: '/pilots/pilots/profile',
      rating: '/pilots/pilots/rating',
      recentActivities: '/pilots/activities',
      rejectOrder: '/pilots/orders',
      removeInsurance: '/pilots/documents/insurance',
      removePermit: '/pilots/documents/license',
      removeResource: '/pilots/resources',
      resourceBase: '/pilots/resources',
      specialities: '/pilots/pilots/specialities',
      updateResource: '/pilots/upload/resource-update',
      uploadDocs: '/pilots/orders',
      verify: {
        sms: '/pilots/pilots/verify-phone',
        token: '/pilots/pilots/verify-phone-token',
        order: '/pilots/orders'
      },
    }
  },
  apiValidationParams: {
    password: {
      minlength: 6
    },
    zip: {
      maxlength: 8
    },
    language: {
      maxlength: 2
    },
    phoneVerificationCode: {
      length: 6
    },
    strMaxlength: 255
  },
  widgetTypes: {
    CALENDAR: 'calendar',
    MAP_SELECTION: 'map_selection'
  }
};
