import {HttpClient} from '@angular/common/http';
import {NgModule, ModuleWithProviders} from '@angular/core';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';

/* providers and shared services */
import {Broadcast} from '../../common/services/broadcast';
import {CommonModule} from '@angular/common';
import {createTranslateLoader} from '../../common/services/api/util';
import {RouterModule} from '@angular/router';
import {Session} from './util';
import {Util} from './api/util';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild({
      loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
      }
    }),
    RouterModule
  ],
  exports: [
    CommonModule,
    TranslateModule
  ]
})
export class SharedServicesModule {

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedServicesModule,
      providers: [
        Session,
        Broadcast,
        Util,
      ]
    };
  }
}
