
import {mergeMap, map, filter} from 'rxjs/operators';
import {Router, ActivatedRoute} from '@angular/router';
import {Component, OnDestroy} from '@angular/core';
import {NavigationEnd} from '@angular/router';

@Component({
  selector: 'base-jobs',
  template: ''
})
export class BaseJobsComponent implements OnDestroy {

  private excludes: Array<any> = ['details', 'quote', 'summary'];
  public hideMenu = false;
  public activeRoute = '';
  private routeListener: any;
  private activeRouteListener: any;

  constructor(private router: Router, protected active: ActivatedRoute) {
    this.routeListener = router.events.pipe(
      filter(e => e instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.hideMenu = this.excludes.filter(ex => event.url.indexOf(ex) !== -1).length > 0;
      });

    this.activeRouteListener = router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(() => this.active),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data))
      .subscribe((event) => {
        this.activeRoute = event.name;
      });
  }

  ngOnDestroy(): void {
    this.routeListener.unsubscribe();
    this.activeRouteListener.unsubscribe();
  }
}
