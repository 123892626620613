
import {finalize} from 'rxjs/operators';

import {Component, ViewChild, OnInit, Output, EventEmitter} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {Client} from '../../../common/services/api/client';
import {LoadingComponent} from '../../../common/components/loading/loading.component';
import {Pilot} from '../../../common/services/api/pilot';

@Component({
  selector: 'visitor-login',
  templateUrl: './visitor-login.component.html'
})
export class VisitorLoginComponent implements OnInit {

  public serverError: boolean;
  public loginForm: FormGroup;

  @ViewChild(LoadingComponent, { static: false }) loading: LoadingComponent;

  @Output() authenticationSuccess: EventEmitter<any> = new EventEmitter();

  constructor(private pilotService: Pilot, protected clientService: Client, private router: Router) {
    this.loginForm = new FormGroup({
      email: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    });
  }

  ngOnInit() {

  }

  public submit(event: any) {
    this.serverError = false;
    if (this.loginForm.valid) {
      this.loading.toggle();
      const load = this.loginForm.getRawValue();
      this.clientService.auth(load.email, load.password).pipe(finalize(() => {
        this.loading.toggle();
      })).subscribe(res => {
        const profile = res;
        this.authenticationSuccess.emit(profile);
        // Add here as well!!
      }, e => {
        this.serverError = true;
      });
    } else {
      // console.log('Form not valid');
    }
  }
}
