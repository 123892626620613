import { NgModule } from '@angular/core';

/* custom pipes */
import { DynamicDatePipe } from './dynamicDate.pipe';
import { DynamicDecimalPipe } from './dynamicDecimal.pipe';
import { FileSizePipe } from './fileSize.pipe';
import { SafePipe } from './safe.pipe';
import { TruncatePipe } from './truncate.pipe';

/* pipes */
@NgModule({
  declarations: [
    DynamicDatePipe,
    DynamicDecimalPipe,
    FileSizePipe,
    SafePipe,
    TruncatePipe,
  ],
  exports: [
    DynamicDatePipe,
    DynamicDecimalPipe,
    FileSizePipe,
    SafePipe,
    TruncatePipe,
  ]
})
export class SharedPipesModule {
}
