import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NotFoundComponent } from './common/components/404/404.component';
import { ExternalOrderComponent } from './common/components/external-order/external-order.component';
import {
  ImmoviewerDrohnenserviceComponent
} from './common/components/external-order/immoviewer-drohnenservice/immoviewer-drohnenservice.component';
import { ImmoviewerErfassungComponent } from './common/components/immoviewer-erfassung/immoviewer-erfassung.component';
import { HomedayExternalOrderComponent } from './common/components/homeday-external-order/homeday-external-order.component';
import { PilotAuthGuard, ClientAuthGuard } from './common/services/api/util';
import {
  SolyticMarketingComponent
} from './common/components/solytic-droneservices/solytic-marketing/solytic-marketing.component';
import {
  SolyticPhotovoltaicComponent
} from './common/components/solytic-droneservices/solytic-photovoltaic/solytic-photovoltaic.component';

export const appRoutes: Routes = [

  // will disable for now since client should buy packages on homepage and not order through order funnel
  /*{
    path: 'client/order',
    loadChildren: './client/order/order.module#OrderModule'
  },*/
  {
    path: 'client/password',
    loadChildren: './client/password/password.module#PasswordModule'
  },
  {
    path: 'client/signin',
    loadChildren: './client/signin/signin.module#SignInModule',
  },
  {
    path: 'client/signup',
    loadChildren: './client/signup/signup.module#SignUpModule'
  },
  {
    path: 'client',
    loadChildren: './client/client.module#ClientModule',
    canActivate: [ClientAuthGuard]
  },
  {
    path: 'pilot/password',
    loadChildren: './pilot/password/password.module#PasswordModule'
  },
  {
    path: 'pilot/signin',
    loadChildren: './pilot/signin/signin.module#SignInModule'
  },
  {
    path: 'pilot/signup',
    loadChildren: './pilot/signup/signup.module#SignUpModule'
  },
  {
    path: 'pilot',
    loadChildren: './pilot/pilot.module#PilotModule',
    canActivate: [PilotAuthGuard]
  },
  // {
  //   path: 'invite/:hash',
  //   loadChildren: './client/invite/invite.module#InviteModule'
  // },
  {
    path: '404',
    component: NotFoundComponent
  },
  // {
  //   path: 'external-order',
  //   component: ExternalOrderComponent,
  //   children: [
  //     {
  //       path: 'immoviewer-drohnenservice',
  //       component: ImmoviewerDrohnenserviceComponent
  //     }
  //   ]
  // },
  // {
  //   path: 'external-order/solytic-photovoltaic',
  //   component: SolyticPhotovoltaicComponent
  // },
  // {
  //   path: 'external-order/solytic-marketing',
  //   component: SolyticMarketingComponent
  // },
  // {
  //   path: 'immoviewer-erfassung',
  //   component: ImmoviewerErfassungComponent
  // },
  // {
  //   path: 'homeday-external-order',
  //   component: HomedayExternalOrderComponent
  // },
  {
    path: '',
    loadChildren: './visitor/visitor.module#VisitorModule',
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

export const declarations = [
  ExternalOrderComponent,
  HomedayExternalOrderComponent,
  ImmoviewerDrohnenserviceComponent,
  ImmoviewerErfassungComponent,
  NotFoundComponent,
  SolyticMarketingComponent,
  SolyticPhotovoltaicComponent
];
