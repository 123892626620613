import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap';
import * as moment from 'moment';

import { countryList } from '../../../services/util';
import { environment } from '../../../../../environments/environment';
import { Validator } from '../../../services/validator';
import { UiLanguageService } from '../../../services/ui-language.service';
import { Util } from '../../../services/api/util';

@Component({
  selector: 'common-solytic-marketing-order',
  templateUrl: './solytic-marketing.html',
  styleUrls: ['./solytic-marketing.scss']
})

export class SolyticMarketingComponent implements OnInit {

    @ViewChild('address', { static: false }) addressElementRef: ElementRef;
    @ViewChild('mediaModal', { static: false }) public mediaModal: ModalDirective;
    public submitted: boolean;
    public form: FormGroup;
    public env: any = environment;
    public countries: Array<any> = countryList;
    public minDate = this.addBusinessDays(moment(), 3);
    public maxDate = moment().add(1, 'year');
    public deadlineFilter = (d: moment.Moment): boolean => {
      const day = d.day();
      // Prevent Saturday and Sunday from being selected.
      return day !== 0 && day !== 6;
    }
    private addBusinessDays(date, daysToAdd) {
      let cnt = 0;
      let tmpDate = moment(date);
      while (cnt < daysToAdd) {
          tmpDate = tmpDate.add('days', 1);
          if (tmpDate.day() !== 0 && tmpDate.day() !== 6) {
              cnt = cnt + 1;
          }
      }
      return tmpDate;
    }

    constructor(
        private adapter: DateAdapter<any>,
        private uiLanguageService: UiLanguageService,
        private service: Util) {
      const currentUiLanguage = this.uiLanguageService.getUiLanguage();
      this.adapter.setLocale(currentUiLanguage);
    }

    ngOnInit(): void {
      this.form = new FormGroup({
        address: new FormControl('', Validators.required),
        billing_address: new FormControl('', Validators.required),
        city: new FormControl(''),
        company: new FormControl('', Validators.required),
        country: new FormControl(''),
        datatoc: new FormControl('', Validators.required),
        deadline: new FormControl(''),
        email: new FormControl('', [Validator.mail, Validators.required]),
        first_name: new FormControl('', Validators.required),
        last_name: new FormControl('', Validators.required),
        output: new FormControl(''),
        package: new FormControl('', Validators.required),
        phone: new FormControl('', Validators.required),
        province: new FormControl(''),
        street: new FormControl(''),
        toc: new FormControl('', Validators.required),
        zip_code: new FormControl(''),
      });
    }

    public onAddressSelect(selected: any): void {
      this.form.get('zip_code').setValue(selected.getPlaceComponent(selected.place, 'postal_code'));
      this.form.get('city').setValue(selected.getPlaceComponent(selected.place, 'locality'));
      this.form.get('province').setValue(selected.getPlaceComponent(selected.place, 'administrative_area_level_1'));
      this.form.get('street').setValue(selected.getPlaceComponent(selected.place, 'route')
      + ' ' + (selected.getPlaceComponent(selected.place, 'street_number')));
      const countryName = selected.getPlaceComponent(selected.place, 'country');
      const country = countryList.find(c => c.name === countryName);
      if (country) {
        this.form.get('country').setValue(country.iso);
      }
    }

    public onSubmit(): void {
      this.submitted = true;
      if (this.form.valid) {
        const payload = this.form.getRawValue();
        console.log(payload);
        if (payload.deadline) {
          payload.deadline = moment(payload.deadline).format('YYYY-MM-DD');
        }
        this.service.solyticExternalOrder(payload).subscribe(res => {
        this.submitted = false;
        this.mediaModal.show();
        }, e => {
          console.log('Error while sending contact request ', e);
        });
       }
    }
}

