import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'widget-map-selection',
  templateUrl: './map-selection.html'
})
export class WidgetMapSelectionComponent {

  @Output() public popData: EventEmitter<any> = new EventEmitter();

  /* public onDataSelect(selected: any): void {
    // console.log("onDataSelect triggered");
    this.popData.emit(selected);
  } */

  public onData(event: any): void {
    this.popData.emit(event);
  }
}
