import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material';

import { DialogComponent } from './dialog.component';

@Injectable()
export class DialogService {

  constructor(public dialog: MatDialog) { }

  showDialog(title?: string, message?: string, input?: boolean,
     img?: string, confirm?: boolean): Observable<boolean> {

    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        confirm,
        input,
        img,
        message,
        title
      }
    });

    if (confirm) {
      return dialogRef.afterClosed();
    }
  }

}
