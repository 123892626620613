import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
declare let google: any;

@Component({
  selector: 'widget-location-distance',
  templateUrl: './distance.html'
})
export class LocationDistanceComponent implements OnChanges {

  public locationInfo: any = {};

  @Input() destinations: Array<any> = [];
  @Input() origins: Array<any> = [];

  public initDistanceMatrix(destinations: Array<any>, origins: Array<any>): void {
    const service = new google.maps.DistanceMatrixService();
    service.getDistanceMatrix({
      origins: origins,
      destinations: destinations,
      travelMode: google.maps.TravelMode.DRIVING,
    }, (response, status) => {
      try {
        this.locationInfo = response.rows[0].elements[0];
      } catch (e) {
        this.locationInfo = {};
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.destinations.length > 0 && this.origins.length > 0) {
      this.initDistanceMatrix(this.destinations, this.origins);
    }
  }
}
