import {Component} from '@angular/core';

import {Broadcast} from '../../../common/services/broadcast';

@Component({
  selector: 'base-saving-process',
  template: ''
})
export class BaseSavingProcessComponent {
  public visible: boolean;

  constructor(protected broadcaster: Broadcast) {
    this.broadcaster.event.subscribe(payload => {
      this.toggle(payload.type === this.broadcaster.events.showSavePanel.type);
    });
  }

  public toggle(visible: boolean): void {
    this.visible = visible;
  }
}
