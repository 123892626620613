
import {finalize} from 'rxjs/operators';
import {Component, OnInit, Inject, ViewChild} from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';

import {AccountInterface} from '../../../../common/interfaces/service';
import {LoadingComponent} from '../../../../common/components/loading/loading.component';
import {Validator} from '../../../../common/services/validator';

@Component({
  selector: 'base-password-reset',
  template: ''
})
export class BasePasswordResetComponent implements OnInit {

  public submitted = false;
  public done = false;
  public serverError: string;
  public form: FormGroup;
  public meta: any = {};

  @ViewChild(LoadingComponent, { static: false }) loading: LoadingComponent;

  constructor(@Inject('AccountInterface') protected service: AccountInterface) {
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validator.mail])
    });
  }

  private onSubmit(): void {
    if (this.serverError) {
      this.serverError = null;
    }
    this.submitted = true;
    if (this.form.valid) {
      this.loading.toggle();
      const email = this.form.get('email');
      this.service.requestNewPassword(email.value).pipe(finalize(() => {
        this.loading.toggle();
      })).subscribe(res => {
        this.done = true;
      }, e => {
        this.serverError = e;
      });
    }
  }
}
