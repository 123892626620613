
import {finalize} from 'rxjs/operators';
import {Component, OnInit, Inject, ViewChild} from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';

import {LoadingComponent} from '../../../common/components/loading/loading.component';
import {environment} from '../../../../environments/environment';
import {SignUpInterface} from '../../interfaces/service';
import {Validator} from '../../services/validator';

const countries = require('country-data').countries.all;

@Component({
  selector: 'base-signup',
  template: ''
})
export class BaseSignupComponent implements OnInit {

  protected countries: Array<any> = countries.filter(c => c.countryCallingCodes.length > 0);
  protected apiValidationParams: any = environment.apiValidationParams;
  protected serverErrors: Array<any> = [];
  protected submitted = false;
  public done = false;
  public form: FormGroup;

  @ViewChild(LoadingComponent, { static: false }) loading: LoadingComponent;

  constructor(@Inject('SignUpInterface') private service: SignUpInterface) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  protected getInitialCountryCode(): number {
    return this.countries.findIndex(c => c.alpha3 === 'DEU');
  }

  protected initForm(isPilot: boolean = false): void {
    this.form = new FormGroup({
      first_name: new FormControl('', [Validators.maxLength(this.apiValidationParams.strMaxlength), Validators.required]),
      last_name: new FormControl('', [Validators.maxLength(this.apiValidationParams.strMaxlength), Validators.required]),
      tel_code: new FormControl(this.getInitialCountryCode(), Validators.required),
      tel: new FormControl('', [Validators.maxLength(this.apiValidationParams.strMaxlength), Validators.required]),
      email: new FormControl('', [Validators.maxLength(this.apiValidationParams.strMaxlength), Validators.required, Validator.mail]),
      password: new FormControl('', [Validators.minLength(this.apiValidationParams.password.minlength), Validators.required]),
      toc: new FormControl('', [Validators.requiredTrue]),
      datatoc: new FormControl('', [Validators.requiredTrue]),
    }); // FF-2182 Removed rePassword because it delays the user registration, he can reset it if he did a mistake
  }

  protected onSubmit(): boolean {
    this.submitted = true;
    if (this.serverErrors.length > 0) {
      this.serverErrors = [];
    }
    if (this.form.valid) {
      this.loading.toggle();
      const payload = this.form.getRawValue();
      const country = this.countries[payload.tel_code];
      payload.tel_code = country.countryCallingCodes[0];
      payload.phone = payload.tel_code + payload.tel;
      payload.country = country.alpha3;
      this.service.signup(payload).pipe(finalize(() => {
        this.loading.toggle();
      })).subscribe(res => {
        this.done = true;
      }, e => {
        this.serverErrors = [e.error];
      });
    }
    return false;
  }
}
