import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {environment} from '../../../../environments/environment';

@Injectable()
export class VerifyPhoneService {

  constructor(private http: HttpClient) {
  }

  public verifyPhone(type: 'sms' | 'voice', userType: 'pilot' | 'client'): Observable<any> {
    return this.http.post(environment.apiBaseUrl + environment.endpoints[userType].verify[type], null);
  }

  public verifyPhoneToken(token: string, userType: 'pilot' | 'client'): Observable<any> {
    return this.http.post(environment.apiBaseUrl + environment.endpoints[userType].verify.token, {
      'token': token
    });
  }
}
