import {
  Directive, AfterViewInit, ElementRef, OnChanges, Input, EventEmitter, Output, SimpleChanges
} from '@angular/core';
declare let jQuery: any;

@Directive({
  selector: '[tbSlider]'
})
export class TbSliderDirective implements AfterViewInit {

  private init = false;
  private sliderRefer: any;

  @Output() popData: EventEmitter<any> = new EventEmitter();
  @Input() params: any;

  constructor(public elementRef: ElementRef) {
  }

  private initSlider(): void {
    const elem = this.getJqueryRef();
    if (elem.length && !this.init) {
      this.sliderRefer = elem.slider(this.params);
      this.sliderRefer.on('change', v => {
        if (v.value.newValue) {
          this.popData.emit(v.value.newValue);
        }
      });
      this.init = true;
    }
  }

  private getJqueryRef(): any {
    return jQuery(this.elementRef.nativeElement);
  }

  public setTextIntoHandle(txt: string): void {
    const elem = this.getJqueryRef();
    const handle = elem.prev().find('.slider-handle');
    if (handle.length) {
      handle.first().text(txt);
    }
  }

  public pushValue(value: number, setText: boolean = false): void {
    this.sliderRefer.slider('setValue', value);
    if (setText) {
      this.setTextIntoHandle(value.toString());
    }
  }

  ngAfterViewInit(): void {
    this.initSlider();
  }
}
