import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';

import {isMobile} from '../../../../common/services/util';

@Component({
  selector: 'common-activity-feed',
  templateUrl: './activityFeed.html',
})
export class ActivityFeedComponent {

  @Input() activities: Array<any> = [];
  @Input() context: string;

  public isMobile: Function = isMobile;

  constructor(private router: Router) {
  }

  public toOverview(activity: any): void {
    if (this.isMobile()) {
      this.router.navigate(['/' + this.context + '/jobs/details', activity.order_id, 'overview']);
    }
  }
}
