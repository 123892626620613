import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID} from '@angular/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class Session {

  public keys = {
    ACCESS_TOKEN: 'access-token', // Todo: FF-2268 make sure the access-token expires
    TOKEN_V3: 'token_v3' // Store in session until we need it again
  };

  constructor(@Inject(PLATFORM_ID) private platformId) {
  }

  public get(key: string): any {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem(key);
    } else {
      return undefined;
    }
  }

  public set(key: string, val: string): void {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.setItem(key, val);
    } else {
      return undefined;
    }
  }

  public remove(key: string): any {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.removeItem(key);
    } else {
      return undefined;
    }
  }
}

export function isMobile(): boolean {
  let check = false;
  (function (a) {
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris/i.test(a)
      || /kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp/i.test(a)
      || /series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)
      || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi/i.test(a.substr(0, 4))
      || /an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)/i.test(a.substr(0, 4))
      || /bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw/i.test(a.substr(0, 4))
      || /da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)/i.test(a.substr(0, 4))
      || /esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie/i.test(a.substr(0, 4))
      || /hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)/i.test(a.substr(0, 4))
      || /i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu/i.test(a.substr(0, 4))
      || /jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])/i.test(a.substr(0, 4))
      || /libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef/i.test(a.substr(0, 4))
      || /mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)/i.test(a.substr(0, 4))
      || /n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)/i.test(a.substr(0, 4))
      || /pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a/i.test(a.substr(0, 4))
      || /qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)/i.test(a.substr(0, 4))
      || /sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)/i.test(a.substr(0, 4))
      || /sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)/i.test(a.substr(0, 4))
      || /tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400/i.test(a.substr(0, 4))
      || /v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)\-/i.test(a.substr(0, 4))
      || /w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) {
          check = true;
    }
  })(navigator.userAgent || navigator.vendor);
  return check;
}

export function chunkArray(arr, size) {
  const sets = [];
  const chunks = arr.length / size;
  let i = 0;

  while (i < chunks) {
    sets[i] = arr.splice(0, size);
    i++;
  }
  return sets;
}

export let countryList = require('country-data').countries.all
  .filter(c => c.status === 'assigned')
  .map(c => {
      return {
        iso: c.alpha3,
        name: c.name,
        tel: c.countryCallingCodes[0],
        icon: c.emoji
      };
    }
  );

export function listenToAnchors(route: ActivatedRoute) {
  route.fragment.subscribe(f => {
    const element = document.querySelector('#' + f);
    if (element) {
      element.scrollIntoView(true);
    }
  });
}

function fileExtension(url: string): string {
  if (url.indexOf('.') === -1) {
    return '';
  }
  return url.split('.').reverse()[0].toLowerCase();
}

export function isImage(url: string): boolean {
  const formats = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'tiff', 'bmp'];
  return formats.indexOf(fileExtension(url)) > -1;
}
