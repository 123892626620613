import {Component, Input, ViewChild, Output, EventEmitter} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ModalDirective} from 'ngx-bootstrap';

import {AbstractUser} from '../../../common/services/api/abstract.user';
import {Client} from '../../../common/services/api/client';
import {countryList} from '../../../common/services/util';
import {environment} from '../../../../environments/environment';
import {Pilot} from '../../../common/services/api/pilot';
import {Session} from '../../../common/services/util';
import {VerifyPhoneService} from '../../../common/services/api/verify-phone';

@Component({
  selector: 'common-verify-phone',
  templateUrl: './verify-phone.html'
})
export class VerifyPhoneComponent {

  public countries: Array<any> = countryList;
  public formVerificationTypes: any = {
    SMS: 'sms'
  };
  public verifyPhoneForm: FormGroup;
  public addPhoneForm: FormGroup;
  public env: any = environment;

  @ViewChild('phoneModal', { static: false }) public phoneModal: ModalDirective;
  @ViewChild('verifyPhoneModal', { static: false }) public verifyPhoneModal: ModalDirective;

  @Output() verified = new EventEmitter<any>();
  @Input() title: string = null;

  public phoneVerificationSchema = {
    code: null,
    number: null,
    type: null
  };

  public user: AbstractUser;

  constructor(private service: VerifyPhoneService, private sess: Session, private pilot: Pilot, private client: Client) {

    const token = this.sess.get(this.sess.keys.ACCESS_TOKEN);
    if (token) {
      this.user = token.startsWith('p') ? this.pilot : this.client;
    } else {
      // console.log('something went wrong');
    }

    this.addPhoneForm = new FormGroup({
      code: new FormControl('', Validators.required),
      number: new FormControl('', Validators.required)
    });

    this.verifyPhoneForm = new FormGroup({
      code: new FormControl('', [
        Validators.required,
        Validators.maxLength(this.env.apiValidationParams.phoneVerificationCode.length),
        Validators.minLength(this.env.apiValidationParams.phoneVerificationCode.length)
      ])
    });

    this.addPhoneForm.valueChanges.subscribe(ch => {
      if (ch.code && ch.code !== 'undefined') {
        this.phoneVerificationSchema.code = ch.code;
      } else {
        ch.code = null;
      }
    });

  }


  public getPhoneNumber() {
    let number = this.addPhoneForm.get('number').value;
    if (number[0] === '0') {
      number = number.slice(1);
    }
    const code = this.addPhoneForm.get('code').value;
    return code + number;
  }

  public performPhoneVerification(type: 'sms'): void {
    this.addPhoneForm.markAsTouched();
    const oldModal = this.phoneModal;
    const modal = this.verifyPhoneModal;

    if (this.addPhoneForm.valid) {
      this.user.save({
        phone: this.getPhoneNumber()
      }).subscribe(() => {
        this.service.verifyPhone(type, this.user.getUserType()).subscribe(
          (res) => {
            if (res) {
              oldModal.hide();
              modal.show();
            }
          },
          (err) => {
            alert(err.error.message);
          }
        );
      });
    } else {
      // console.log(this.addPhoneForm);
    }
  }

  public verifyCode() {
    const modal = this.verifyPhoneModal;
    const code = this.verifyPhoneForm.get('code').value;

    this.service.verifyPhoneToken(code, this.user.getUserType()).subscribe(
      (res) => {
        if (res) {
          this.verified.emit({success: true, number: this.getPhoneNumber()});
          modal.hide();
        }
      },
      (err) => {
        alert(err.error.message);
      }
    );

  }

}
