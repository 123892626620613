import {Injectable, EventEmitter} from '@angular/core';

export interface IEvent {
  type: string;
  data: any;
}

@Injectable()
export class Broadcast {

  public event: EventEmitter<IEvent> = new EventEmitter();
  public events: any = {
    showSavePanel: {
      type: 'showSavePanel',
      data: true
    },
    hideSavePanel: {
      type: 'hideSavePanel',
      data: true
    }
  };

  public emit(event: IEvent): void {
    this.event.emit(event);
  }
}
