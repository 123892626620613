import {Component, ViewChild, Output, EventEmitter, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {ModalDirective} from 'ngx-bootstrap';

@Component({
  selector: 'common-google-autocomplete',
  templateUrl: './autocomplete.html'
})
export class GoogleAutocompleteComponent implements OnInit {

  @ViewChild('autocompleteModal', { static: false }) public autocompleteModal: ModalDirective;
  @Output() onAddressSelect: EventEmitter<any> = new EventEmitter();

  private payload: any = {};
  public form: FormGroup;

  ngOnInit(): void {
    this.form = new FormGroup({
      search: new FormControl('')
    });
  }

  public onSelect(selected: any): void {
    this.payload = selected;
  }

  public onSave(): void {
    this.onAddressSelect.emit(this.payload);
    this.autocompleteModal.hide();
  }

  public openModal(): void {
    this.autocompleteModal.show();
  }
}
