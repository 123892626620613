import {ElementRef, OnInit, Directive, Input} from '@angular/core';
import {FormControl} from '@angular/forms';

import {environment} from '../../../../../environments/environment';

declare let jQuery: any;

@Directive({
  selector: '[characterCounter]'
})
export class CharacterCounterDirective implements OnInit {

  public counter = 0;
  private env: any = environment;

  @Input() max: number = this.env.apiValidationParams.strMaxlength;
  @Input() control: FormControl;

  constructor(public elementRef: ElementRef) {
  }

  ngOnInit() {
    this.counter = this.max - this.control.value.length;
    this.control.valueChanges.subscribe(value => {
      this.counter = this.max - value.length;
      this.updateCounterView();
    });
    this.updateCounterView();
  }

  private updateCounterView(): void {
    jQuery(this.elementRef.nativeElement).next().filter('.character-counter').text(this.counter);
  }
}
