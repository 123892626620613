import { Component, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'common-loading',
  templateUrl: './loading.html'
})
export class LoadingComponent {
  public show: boolean;

  constructor(private _changeDetectionRef: ChangeDetectorRef) {
  }

  public toggle(): void {
    this.show = !this.show;
    this._changeDetectionRef.detectChanges();
  }
}
