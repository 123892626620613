
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import ApiObject from '../state/api-object.service';
import {Conversation} from './conversation';
import {environment} from '../../../../environments/environment';
import {Environment} from './environment';

@Injectable()
export abstract class AbstractUser extends ApiObject {
  private env: Environment = environment;

  abstract getUserType(): 'pilot' | 'client';

  protected getMyEnpoint(endpoint: string) {
    return this.env.apiBaseUrl + this.env.endpoints[this.getUserType()][endpoint];
  }

  public getConversations() {
    return this.http.get(this.getMyEnpoint('conversations')).pipe(
    map((res: any) => {
      return res.map((x) => {
        const c = new Conversation(this.http);
        c.stream.next({
          type: 'INIT',
          data: x
        });
        c.resourceUrl = this.getMyEnpoint('conversations') + '/' + x.id;
        return c;
      });
    }));
  }

  protected getResourceUrl(method: 'PUT' | 'POST' | 'GET' | 'DELETE') {
    if (method === 'GET') {
      return this.getMyEnpoint('profile');
    }
    return this.resourceUrl;
  }

}
