
import {of as observableOf, Observable} from 'rxjs';

import {filter, map, share} from 'rxjs/operators';
import AbstractObject from './abstract-object';
import {HttpClient} from '@angular/common/http';




export default class ApiObject extends AbstractObject {
  public resourceUrl = '';
  private alive = false;

  public save(update: any): Observable<any> {
    this.change(update);
    if (this.http) {
      const ob = this.http.put(this.getResourceUrl('PUT'), this.getData()).pipe(map(x => x), share());
      ob.subscribe();
      return ob;
    } else {
      return observableOf(this.getData());
    }
  }

  protected getResourceUrl(method: 'PUT' | 'POST' | 'GET' | 'DELETE') {
    return this.resourceUrl;
  }

  public pull(): Observable<any> {
    const ob = this.http.get(this.getResourceUrl('GET')).pipe(share());
    ob.subscribe((res) => {
      this.stream.next({ type: 'CHANGE', data: res });
    });
    return ob.pipe(map(x => x));
  }

  protected init(isInit: boolean = false): Observable<any> {
    const ob = this.http
    .get(this.getResourceUrl('GET'));
    ob.subscribe((res) => {
      this.stream.next({ type: 'INIT', data: res });
    });
    return ob.pipe(map(x => x));
  }

  constructor(protected http?: HttpClient) {
    super({
      'INIT': (data) => {
        if (Object.keys(data).length === 0) {
          this.pull();
        } else {
          this.stream.next({ type: 'CHANGE', data: data });
        }
        this.alive = true;
      },
      'FLUSH': () => {
        this.alive = false;
      }
    });
  }

  public getDataWhenReady(): Observable<any> {
    const data = this.getData();
    if (Object.keys(data).length === 0) {
      return this.stream.pipe(
        filter((x) => x.type === 'INIT'),
        map((x) => x.data));
    } else {
      return observableOf(data);
    }
  }

  public isAlive() {
    return this.alive;
  }
}
