import {AbstractControl} from '@angular/forms';

export class Validator {

  static mail(control: AbstractControl): ValidationResult {
    // tslint:disable-next-line:max-line-length
    const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (control.value !== '' && (control.value.length <= 5 || !emailRegExp.test(control.value))) {
      return {mail: true};
    }
    return null;
  }

  static matchTo(control: AbstractControl): ValidationResult {
    const isInvalid = control.value !== this['value'];
    return !isInvalid ? null : {matchTo: isInvalid};
  }

  static mustBeChecked(control: AbstractControl): ValidationResult {
    if (!control.value) {
      return {checked: true};
    }
    return null;
  }

  static digitsOnly(control: AbstractControl): ValidationResult {
    const regexp = /^[0-9]*$/;
    if (!regexp.test(control.value)) {
      return {digits: true};
    }
    return null;
  }

  static link(control: AbstractControl): ValidationResult {
    const regexp = /^s?https?:\/\/[-_.!~*'()a-zA-Z0-9;\/?:\@&=+\$,%#]+$/;
    if (!regexp.test(control.value)) {
      return {link: true};
    }
    return null;
  }

  static videoPortal(control: AbstractControl): ValidationResult {
    // tslint:disable-next-line:max-line-length
    const regexp = /(http:\/\/|https:\/\/|)(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/;
    if (!regexp.test(control.value)) {
      return {videoPortal: true};
    }
    return null;
  }
}

interface ValidationResult {
  [key: string]: boolean;
}
