import {Component, Input} from '@angular/core';

@Component({
  selector: 'widget-recent-activities',
  templateUrl: './recentActivities.html'
})
export class RecentActivitiesComponent {

  @Input() activities: Array<any> = [];
}
