import {
  Directive,
  AfterViewInit,
  AfterViewChecked,
  ElementRef,
  Input,
  Inject,
  OnChanges,
  PLATFORM_ID,
  SimpleChanges
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
declare let jQuery: any;

@Directive({
  selector: '[tbSelect]'
})
export class TbSelectDirective implements AfterViewInit, AfterViewChecked, OnChanges {
  @Input() preSelect: any = null;

  private onShown = 'shown.bs.select';
  private onHidden = 'hidden.bs.select';

  constructor(@Inject(PLATFORM_ID)
      private platformId,
      public elementRef: ElementRef
  ) {}

  private static getPrevButton(current): any {
    const elem = jQuery(current).parent('.bootstrap-select');
    return {
      elem: elem.find('button'),
      className: elem.hasClass('dropup') ? 'dropup-select' : 'dropdown-select'
    };
  }

  private toggleCaret(e: any): void {
    if (isPlatformBrowser(this.platformId)) {
      const isShown = e.type === 'shown';
      const caret = jQuery(this).parents('.dropdown').find('.caret');
      const btn = TbSelectDirective.getPrevButton(this);
      if (isShown) {
        caret.addClass('rotate');
        btn.elem.addClass(btn.className);
      } else {
        caret.removeClass('rotate');
        btn.elem.removeClass(btn.className);
      }
    }
  }

  private initSelectBox(isRefresh: boolean = false): boolean {
    if (isPlatformBrowser(this.platformId)) {
      const elem = jQuery(this.elementRef.nativeElement);
      if (elem.length) {
        if (isRefresh) {
          if (!elem.selectpicker('val')) {
            elem.selectpicker('val', this.preSelect);
          }
          elem.selectpicker('refresh');
          return false;
        }
        elem.selectpicker();
        elem.on(this.onHidden, this.toggleCaret);
        elem.on(this.onShown, this.toggleCaret);
      }
    }
    return false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['preSelect']) {
      this.initSelectBox(true);
    }
  }

  ngAfterViewInit(): void {
    this.initSelectBox();
  }

  ngAfterViewChecked(): void {
    this.initSelectBox(true);
  }
}
