import {Component, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';

@Component({
  selector: 'client-panel-base',
  template: ''
})
export class PanelComponent implements OnChanges {

  @Output() popData: EventEmitter<any> = new EventEmitter();

  @Input() question: any;
  @Input() index: number;
  @Input() total: number;

  ngOnChanges(changes: SimpleChanges): void {
    /*  open 1st question by default  */
    if (changes.index && changes.index.currentValue === 1) {
      this.openPanel();
    }
  }

  public openPanel(): void {
    this.question.show = !this.question.show;
  }
}
