
import {of as observableOf, Observable} from 'rxjs';

import {share, map} from 'rxjs/operators';


import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {AbstractUser} from './abstract.user';
import {environment} from '../../../../environments/environment';
import {Session} from '../util';

@Injectable()
export class Pilot extends AbstractUser {

  public authPromise: Promise<boolean>;
  public activeJobs: Observable<any>;
  public previousJobs: Observable<any>;

  public getUserType(): 'pilot' | 'client' {
    return 'pilot';
  }

  constructor(http: HttpClient, private sess: Session) {
    super(http);

    this.authPromise = new Promise((resolve) => {
      // console.log("My access token is: ");
      // console.log(this.sess.get(this.sess.keys.ACCESS_TOKEN));
      if (this.sess.get(this.sess.keys.ACCESS_TOKEN) && this.sess.get(this.sess.keys.ACCESS_TOKEN)[0] === 'p') {
        this.initFromObservable(
          this.http.get(environment.apiBaseUrl + environment.endpoints.getPilotProfile)
        ).subscribe(
          () => {
            resolve(true);
          },
          () => {
            resolve(false);
          }
        );
      } else {
        resolve(false);
      }
    });
  }

  private initFromObservable(ob: Observable<any>) {
    ob.subscribe(
      (res) => {
        const data = res;
        this.resourceUrl = environment.apiBaseUrl + environment.endpoints.updateProfile + '/' + data.pilot_id;
        if (data.token) {
          this.sess.set(this.sess.keys.ACCESS_TOKEN, data.token);
          this.sess.set(this.sess.keys.TOKEN_V3, data.token_v3);
        }
        this.stream.next({
          type: 'INIT',
          data: data
        });
      },
      (e) => {
        // todo handle error here
        // console.log('error while login ', e);
      }
    );
    return ob;
  }

  public auth(email: string, password: string) {
    return this.initFromObservable(
      this.http.post(environment.apiBaseUrl + environment.endpoints.getPilotProfile, null, {
        headers: new HttpHeaders({'auth-token': btoa(unescape(encodeURIComponent(email + ':' + password)))})
      }).pipe(share())
    );
  }

  public logout() {
    this.sess.set(this.sess.keys.ACCESS_TOKEN, null);
    this.sess.set(this.sess.keys.TOKEN_V3, null);
    this.flush();
  }

  public getRecentActivities(): Observable<any> {
    return this.http.get(environment.apiBaseUrl + environment.endpoints.pilot.recentActivities);
  }

  public completeProfileActions(): Observable<any> {
    return this.http.get(environment.apiBaseUrl + environment.endpoints.completeProfActions);
  }

  public getEquipmentManufacturers(): Observable<any> {
    return this.http.get(environment.apiBaseUrl + environment.endpoints.equipmentManufacturers);
  }

  public getEquipmentPackages(): Observable<any> {
    return this.http.get(environment.apiBaseUrl + environment.endpoints.equipmentPackages);
  }

  public getModels(id: Number): Observable<any> {
    return this.http.get(environment.apiBaseUrl + environment.endpoints.equipmentDrones + '/' + id);
  }

  public getCameras(id: Number): Observable<any> {
    return this.http.get(environment.apiBaseUrl + environment.endpoints.equipmentCameras + '/' + id);
  }

  public createEquipmentPackage(payload: Array<any>): Observable<any> {
    return this.http.post(environment.apiBaseUrl + environment.endpoints.equipmentPackages, payload);
  }

  public getProfile(): Observable<any> {
    return this.http.get(environment.apiBaseUrl + environment.endpoints.getPilotProfile);
  }

  public updateProfile(payload: any, id: number): Observable<any> {
    return this.http.put(environment.apiBaseUrl + environment.endpoints.updateProfile + '/' + id, payload);
  }

  public getJobById(id: number, isPrevious: boolean): Observable<any> {
    const url = isPrevious ? environment.endpoints.getPrevJobById : environment.endpoints.getJobById;
    return this.http.get(environment.apiBaseUrl + url + '/' + id);
  }

  public getActivities(): Observable<any> {
    return this.http.get(environment.apiBaseUrl + environment.endpoints.activities);
  }

  public getActiveJobs(): Observable<any> {
    return this.http.get(environment.apiBaseUrl + environment.endpoints.pilot.activeJobs);
  }

  public getPreviousJobs(): Observable<any> {
    return this.http.get(environment.apiBaseUrl + environment.endpoints.pilot.previousJobs);
  }

  public permission(id: number): Observable<any> {
    return this.http.get(environment.apiBaseUrl + environment.endpoints.pilot.permission + '/' + id);
  }

  public deleteEquipmentPackage(id: number): Observable<any> {
    return this.http.delete(environment.apiBaseUrl + environment.endpoints.deleteEquipmentPackage + '/' + id);
  }

  public changePassword(payload: any): Observable<any> {
    return this.http.post(environment.apiBaseUrl + environment.endpoints.changePassword, payload);
  }

  public getRecentLogIns(): Observable<any> {
    return this.http.get(environment.apiBaseUrl + environment.endpoints.recentLogins);
  }

  public getNotificationsSettings(): Observable<any> {
    return this.http.get(environment.apiBaseUrl + environment.endpoints.notificationsSettings);
  }

  public updateNotificationsSettings(payload: any): Observable<any> {
    return this.http.post(environment.apiBaseUrl + environment.endpoints.notificationsSettings, payload);
  }

  public deleteAccount(): Observable<any> {
    return this.http.delete(environment.apiBaseUrl + environment.endpoints.deleteAccount);
  }

  public getLenses(cameraId: number): Observable<any> {
    return this.http.get(environment.apiBaseUrl + environment.endpoints.equipmentDronesAcc + '/' + cameraId + '/lenses');
  }

  public getSensors(droneId: number): Observable<any> {
    return this.http.get(environment.apiBaseUrl + environment.endpoints.equipmentDronesAcc + '/' + droneId + '/sensors');
  }

  public getQuoteRequests(id?: number): Observable<any> {
    const url = id ? environment.endpoints.quoteRequestById + '/' + id : environment.endpoints.quoteRequests;
    return this.http.get(environment.apiBaseUrl + url);
  }

  public createQuote(orderId: number, payload: any): Observable<any> {
    const url = environment.endpoints.quoteRequest + '/' + orderId + '/order-offer';
    return this.http.post(environment.apiBaseUrl + url, payload);
  }

  public markOrderAsDone(orderId: number): Observable<any> {
    return this.http.put(environment.apiBaseUrl + environment.endpoints.pilot.markOrderAsDone + '/' + orderId, {});
  }

  public getConversationOfOrder(orderId: number, pilotId?: number): Observable<any> {
    const params: HttpParams = new HttpParams()
        .set('expand', 'messages');
    return this.http.get(environment.apiBaseUrl + environment.endpoints.pilot.getConversationOfOrder + '/' + orderId, {
      params: params
    });
  }

  public postMessageToOrderConversation(orderId: number, payload: any): Observable<any> {
    return this.http.post(environment.apiBaseUrl + environment.endpoints.pilot.postMessageToOrderConversation + '/' + orderId, payload);
  }

  public signup(payload: any): Observable<any> {
    return this.initFromObservable(
      this.http.post(environment.apiBaseUrl + environment.endpoints.register, payload).pipe(share())
    );
  }

  public requestNewPassword(email: string): Observable<any> {
    const params: HttpParams = new HttpParams()
        .set('email', email)
        .set('version', '2'); // tmp just to not break old website
    return this.http.get(environment.apiBaseUrl + environment.endpoints.pilot.newPassRequest, {
      params: params
    });
  }

  public changePasswordWithToken(payload: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders()
        .append('reset-token', payload.token);
    return this.http.post(environment.apiBaseUrl + environment.endpoints.pilot.newPassRequest, payload, {
      headers: headers
    });
  }

  public getRating(): Observable<number> {
    const data = this.getData();
    if (data.rating) {
      return observableOf(data.rating);
    } else {
      const ob = <Observable<number>>this.http.get(environment.apiBaseUrl + environment.endpoints.pilot.rating)
      .pipe(
        share(),
        map((x) => {
          return x;
        }));
      ob.subscribe(
        (x) => {
          this.change({rating: x});
        },
        // console.log.bind(console)
      );
      return ob;
    }
  }

  public updateResource(id: number, payload: any): Observable<any> {
    return this.http.put(environment.apiBaseUrl + environment.endpoints.pilot.resourceBase + '/' + id, payload);
  }

  public getProjects() {
    return this.http.get(environment.apiBaseUrl + environment.endpoints.pilot.resourceBase + '?tag=portfolio');
  }

  public getPermits(): Observable<any[]> {
    return this.http.get(environment.apiBaseUrl + environment.endpoints.pilot.permitUpload).pipe(
      map((x: any) => x.sort((a, b) => a.id > b.id)));
  }

  public updateSpecialities(payload: any) {
    return this.http.post(environment.apiBaseUrl + environment.endpoints.pilot.specialities, payload);
  }

  public getSpecialities(): Observable<any> {
    return this.http.get(environment.apiBaseUrl + environment.endpoints.pilot.specialities);
  }

  public getInsurances(): Observable<any[]> {
    return this.http.get(environment.apiBaseUrl + environment.endpoints.pilot.insuranceUpload).pipe(
      map((x: any) => x.sort((a, b) => a.id > b.id)));
  }

  public getResource(resourceId: number): Observable<any> {
    return this.http.get(environment.apiBaseUrl + environment.endpoints.pilot.getResource + '/' + resourceId);
  }

  public removeResource(resourceId: number): Observable<any> {
    return this.http.delete(environment.apiBaseUrl + environment.endpoints.pilot.removeResource + '/' + resourceId);
  }

  public verifyOrder(editor_notes: any, orderId: number): Observable<any> {
    return this.http.put(environment.apiBaseUrl + environment.endpoints.pilot.verify.order + '/' + orderId, editor_notes);
  }

  public removeInsurance(id: number): Observable<any> {
    return this.http.delete(environment.apiBaseUrl + environment.endpoints.pilot.removeInsurance + '/' + id);
  }

  public removePermit(id: number): Observable<any> {
    return this.http.delete(environment.apiBaseUrl + environment.endpoints.pilot.removePermit + '/' + id);
  }

  public rejectOrder(comment: any, orderId: number): Observable<any> {
    return this.http.post(environment.apiBaseUrl + environment.endpoints.pilot.rejectOrder + '/' + orderId + '/reject', comment);
  }

  public addPortfolio(formData: FormData): Observable<any> {
    return this.http.post(environment.apiBaseUrl + environment.endpoints.pilot.portfolioUpload, formData);
  }
}
