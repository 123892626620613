import {ElementRef, NgZone, OnInit, Directive, Input, Output, EventEmitter} from '@angular/core';
import {FormControl} from '@angular/forms';

declare let google: any;

@Directive({
  selector: '[googleAutocomplete]'
})
export class GoogleAutocompleteDirective implements OnInit {

  @Input() control: FormControl;
  @Output() onSelect: EventEmitter<any> = new EventEmitter();

  constructor(public elementRef: ElementRef, private ngZone: NgZone) {
  }

  ngOnInit() {
    const autocomplete = new google.maps.places.Autocomplete(this.elementRef.nativeElement);

    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        const place = google.maps.places.PlaceResult = autocomplete.getPlace();
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }
        if (this.control) {
          this.control.setValue(place.formatted_address);
        }
        this.onSelect.emit({place: place, getPlaceComponent: this.getPlaceComponent});
      });
    });
  }

  public getPlaceComponent(place: any, component: string): string {
    for (let i = 0; i < place.address_components.length; i++) {
      for (let j = 0; j < place.address_components[i].types.length; j++) {
        if (place.address_components[i].types[j] === component) {
          return place.address_components[i].long_name;
        }
      }
    }
    return null;
  }
}
