import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { UiLanguageService } from '../../../common/services/ui-language.service';

export interface Testimonial {
  order: number;
  language: string;
  title: string;
  logoSource: string;
  text: string;
  name: string;
  position: string;
}

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent {

  public currentTestimonial: Testimonial;
  private currentTestimonialIndex = 0;
  private testimonials: Testimonial[] = [];

  constructor(private translate: TranslateService, private uiLanguageService: UiLanguageService) {
    this.getTestimonials();
    translate.onLangChange.subscribe( next => {
      this.getTestimonials();
    });
  }

  public slideLeft(event: any): void {
    if (this.currentTestimonialIndex === 0) {
      this.currentTestimonialIndex = this.testimonials.length - 1;
    } else {
      this.currentTestimonialIndex--;
    }
    this.currentTestimonial = this.testimonials[this.currentTestimonialIndex];
  }

  public slideRight(event: any): void {
    if (this.currentTestimonialIndex === this.testimonials.length - 1) {
      this.currentTestimonialIndex = 0;
    } else {
      this.currentTestimonialIndex++;
    }
    this.currentTestimonial = this.testimonials[this.currentTestimonialIndex];
  }

  private getTestimonials(): void {
    this.translate.get('testimonials').subscribe((res: Array<Testimonial>) => {
      this.testimonials = res;
      this.currentTestimonial = this.testimonials[this.currentTestimonialIndex];
    });

  }

}
