import {
  Directive, ElementRef, HostListener, Inject, Input,
  OnChanges, OnDestroy, OnInit, PLATFORM_ID, SimpleChanges
} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';
import {area, bboxPolygon, bbox, centerOfMass, circle, kinks, point, polygon,  } from '@turf/turf';
import { isPlatformBrowser } from '@angular/common';
import xml2js from 'xml2js';

declare let jQuery: any;
declare let google: any;
declare let window: any;

@Directive({
  selector: '[googleMap]'
})
export class GoogleMapDirective implements OnInit, OnChanges, OnDestroy {

  public polygonArea: any;
  public errorMessage: string;
  private expandUrl = 'https://www.google.com/maps/place/';
  private drawingManager: any = null;
  private areaInfoWindow: any = null;
  private init = false;
  private marker: any;
  private polygon: any;
  private drawing: any = {
    polygon: {},
    meta: {},
    listeners: []
  };
  private map: any;

  @Input() expandOnClick = false;
  @Input() enableDrawing = false;
  @Input() drawingOptions: any = {};
  @Input() options: any = {};
  @Input() zoom = 16;
  @Input() lat: number;
  @Input() lng: number;
  @Input() allCoords: any = [];
  @Input() isAreaSelected = false;

  constructor(
    public translate: TranslateService,
    public elementRef: ElementRef,
    @Inject(PLATFORM_ID) private platformId) {
  }

  @HostListener('click', ['$event'])
  onClick(e) {
    if (this.expandOnClick) {
      const url = this.expandUrl + this.lat + ',' + this.lng + '/@' + this.lat + ',' + this.lng + ',' + this.zoom + 'z';
      window.open(url);
    }
  }

  public getMap(): any {
    return this.map;
  }

  public setCenter(lat: number, lng: number): void {
    if (this.map) {
      this.map.setCenter({lat: lat, lng: lng});
    }
  }

  public centerMarkerEnd(): void {
    google.maps.event.clearListeners(this.map, 'center_changed');
  }

  public centerMarkerStart(): void {
    this.marker.setPosition({lat: this.map.center.lat(), lng: this.map.center.lng()} );
    this.map.addListener('center_changed', () => {
      this.marker.setPosition({lat: this.map.center.lat(), lng: this.map.center.lng()} );
    });
  }

  public makeMarkerVisible(newBool: boolean): void {
    this.marker.setVisible(newBool);
  }

  public setMarkerPosition(lat: number, lng: number): void {
    this.marker.setPosition(new google.maps.LatLng(lat, lng));
  }

  public setZoom(zoom: number): void {
    if (this.map) {
      this.map.setZoom(zoom);
    }
  }

  public addMarker(lat: number, lng: number): void {
    if (this.map) {
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(lat, lng),
        map: this.map,
        icon: 'https://fairfleet.com/a/webassets/assets_homepage/POI_drone_red.png',
        scale: 2,
      });
      this.marker = marker;
      const polygonCoords = [];

      const locationPolygon = new google.maps.Polygon({
          paths: polygonCoords,
          strokeColor: '#2979ff',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#2979ff',
          fillOpacity: 0.35,
          draggable: false,
          editable: true,
          geodesic: false
        });

      locationPolygon.setMap(this.map);
      this.polygon = locationPolygon;
    }
  }

  private initMap(): void {
    const elem = jQuery(this.elementRef.nativeElement);
    if (!this.init && elem.length) {
      const mapParams = {
        mapTypeId: google.maps.MapTypeId.HYBRID,
        tilt: 0,
        zoom: this.zoom
      };
      this.map = new google.maps.Map(elem[0], Object.assign(mapParams, {options: this.options}));
      this.init = true;
      if (this.enableDrawing) {
        this.enableDrawingManager();
      }
      // Remove POIs from all maps
      this.map.setOptions({styles: [
        {featureType: 'poi', stylers: [{ visibility: 'off' }]},
        {featureType: 'transit.station', stylers: [{ visibility: 'off' }]}
      ]});
    }
  }
  public enableCenterZoom(mapElement: HTMLDivElement): void {
    // Enable Center Zoom by doubleClick
    this.map.setOptions({disableDoubleClickZoom: true});
    google.maps.event.addListener(this.map, 'dblclick', () => {
      this.map.setZoom(this.map.getZoom() + 1);
    });

    // Enable Center Zoom by mouseWheel
    this.map.setOptions({scrollwheel: 0});
    mapElement.addEventListener('wheel', (event: any) => {
      const gMapPBC = document.querySelector<HTMLDivElement>('.gm-style-pbc');
      gMapPBC.style.transitionDuration = '0.4s';
      if (event.ctrlKey || event.metaKey) {
        if (event.deltaY && event.deltaY < 0) {
            this.map.setZoom(this.map.getZoom() + 1);
        } else {
            this.map.setZoom(this.map.getZoom() - 1);
        }
        gMapPBC.style.opacity = '0';

        // Disable scrolling of window
        event.preventDefault();
      } else {
        // Show overlay on Map
        gMapPBC.style.opacity = '1';
        document.querySelector<HTMLDivElement>('.gm-style-pbt').innerHTML = this.translate.instant('map-selection.overlay-zoom');

        setTimeout(() => {
          gMapPBC.style.opacity = '0';
        }, 2500);
      }
    });
  }

  public makePolygonEditable(): void {
    this.polygon.setEditable(true);
    this.enableRemovevertices();
  }

  public drawPolygon(inPolygon: any): void {
    this.polygon = new google.maps.Polygon({
          map: this.map,
          paths: inPolygon,
          strokeColor: '#2979ff',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#2979ff',
          fillOpacity: 0.35,
          draggable: false,
          geodesic: false
        });
    this.fitBounds();
  }
  public fitBounds(paddingB = false): any {
    const bounds = new google.maps.LatLngBounds();
    this.polygon.getPath().forEach((l) => {
      bounds.extend(l);
    });
    if (paddingB) {
      const mapDiv = this.map.getDiv();

      const padding = {
        bottom: mapDiv.offsetHeight * 0.1,
        left: mapDiv.offsetWidth * 0.4,
        right: mapDiv.offsetWidth * 0.1,
        top: mapDiv.offsetHeight * 0.1,
      };
      setTimeout(() => {
        this.map.fitBounds(bounds, padding);
      }, 250);

    } else {
      setTimeout(() => {
        this.map.fitBounds(bounds);
      }, 250);
    }

  }

  private enableDetectMarkerMovement(): void {
    google.maps.event.addListener(this.marker, 'dragend', () => this.checkMarkerPolygon());
  }

  private enableRemovevertices(): void {
    google.maps.event.addListener(this.polygon, 'dblclick', function(event) {
      if (typeof event.vertex !== 'undefined') {
        if (this.getPath().getLength() > 3) {
          this.getPath().removeAt(event.vertex);
        }
      }
    });
    google.maps.event.addListener(this.polygon.getPath(), 'insert_at', () => this.checkMarkerPolygon());
    google.maps.event.addListener(this.polygon.getPath(), 'remove_at', () => this.checkMarkerPolygon());
    google.maps.event.addListener(this.polygon.getPath(), 'set_at', () => this.checkMarkerPolygon());
  }

  private enableDrawingManager(): void {
    this.drawingManager = new google.maps.drawing.DrawingManager(this.drawingOptions);
    this.drawingManager.setMap(this.map);

    this.drawing.listeners.push(google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (inPolygon) => {
      this.drawing.polygon = inPolygon;
       if (this.drawingManager.getDrawingMode()) {
        this.drawingManager.setDrawingMode(null);
        this.showAreaInfoWindow();
      }
    }));

    this.drawing.listeners.push(google.maps.event.addListener(this.drawingManager, 'drawingmode_changed', () => {
      if (this.drawingManager.getDrawingMode() != null) {
        this.clearDrawing();
      }
    }));
  }

  public triggerResize(): void {
    google.maps.event.trigger(this.map, 'resize');
  }

  public getAreaMetaData(): any {
    if (this.drawing.polygon && this.drawing.polygon.overlay) {
      const paths = this.drawing.polygon.overlay.getPath().getArray();
      const areaNumber = google.maps.geometry.spherical.computeArea(paths).toFixed(0);
      return {
        area: parseFloat(areaNumber),
        paths: paths.map(x => {
          return {
            lat: x.lat(),
            lng: x.lng()
          };
        })
      };
    }
    return null;
  }

  public setPolygonPath(input: any): void {
    this.polygon.setPaths(input);
    this.checkMarkerPolygon();
  }

  /** Converts degrees minutes secounds direction into decimal degrees  */
  public dmsd2dd(dms: string): number {
    const degrees = parseFloat(dms.split('°')[0]);
    const minutes = parseFloat(dms.split('°')[1].split(`"`)[0]);
    const seconds = parseFloat(dms.split(`'`)[1].split(`"`)[0].replace(',', '.'));
    const direction = dms[dms.length - 1];

    let dd = degrees + minutes / 60 + seconds / ( 60 * 60);
    if (direction.toLowerCase() === 's' || direction.toLowerCase() === 'w' ) {
      dd = dd * -1;
    }
    return dd;
  }

  /** Parse XML to array with {lng, lat}  */
  public parseXML(data) {
    return new Promise(resolve => {
      const parser = new xml2js.Parser(
        {
          trim: true,
          explicitArray: true
        }
      );
      parser.parseString(data, function (err, result) {
        if (result &&
            'kml' in result &&
            'Document' in result.kml &&
            'Placemark' in result.kml.Document[0] &&
            'Polygon' in  result.kml.Document[0].Placemark[0]) {
          const strCoordinates = result.kml.Document[0].Placemark[0].Polygon[0].outerBoundaryIs[0].LinearRing[0].coordinates[0];
          const coords = strCoordinates.split(' ').map(x => {
            const coord = x.split(',');
            return {
              lng: parseFloat(coord[0]),
              lat: parseFloat(coord[1])
            };
          });
          coords.pop();
          resolve(coords);
        } else if (result &&
            'kml' in result &&
            'Document' in result.kml &&
            'Folder' in result.kml.Document[0] &&
            'Placemark' in  result.kml.Document[0].Folder[0] &&
            result.kml.Document[0].Folder[0].Placemark.length === 1 &&
            'Polygon' in  result.kml.Document[0].Folder[0].Placemark[0]) {
          const strCoordinates = result.kml.Document[0].Folder[0].Placemark[0].Polygon[0].outerBoundaryIs[0].LinearRing[0].coordinates[0];
          const coords = strCoordinates.split(' ').map(x => {
            const coord = x.split(',');
            return {
              lng: parseFloat(coord[0]),
              lat: parseFloat(coord[1])
            };
          });
          coords.pop();
          resolve(coords);
        } else {
          resolve(false);
        }
      });
    });
  }

  /** Checks marker Polygon if there is a kniks  */
  public checkMarkerPolygon(): void {
    const polyTurf = this.getPolygonTurf();

    const polygonKinks = kinks(polyTurf).features.length;

    if (polygonKinks === 0) {
      this.isAreaSelected = true;
      this.errorMessage = null;
      this.polygon.setOptions({strokeColor: '#2979ff'});
      this.polygonArea = area(polyTurf).toFixed(0);
    } else {
      this.isAreaSelected = false;
      this.polygon.setOptions({strokeColor: '#2979ff'});
      this.polygonArea = null;
      this.errorMessage = 'kniks';
    }
  }

  /** Remove the polygon by setPath to empty array  */
  public removePolygon(): void {
    this.marker.setDraggable(false);
    this.polygon.setPath([]);
    this.marker.setVisible(true);
    this.isAreaSelected = false;
    this.polygonArea = null;
  }

  public placePolygon(paddingB = false): void {

    this.marker.setVisible(false);
    // Create Point as basis - turfs needs lng, lat...
    const turfPoint = this.getMarkerTurf();
    // Create Circle as Buffer - because buffer distance is not accurate

    const turfCircle = circle(turfPoint, 24.97, {steps: 4, units: 'meters'});
    const turfBBox = bboxPolygon(bbox(turfCircle));

    // Get Points of BBox
    const googleBBoxPoints = [];
    Object.entries(turfBBox.geometry.coordinates[0]).forEach(([key, value]) => {
      googleBBoxPoints.push({lat: value[1], lng: value[0]});
    });
    // Remove last Point (it is the same Poit as the First)
    googleBBoxPoints.pop();


    this.polygon.setPath(googleBBoxPoints);

    this.checkMarkerPolygon();
    this.enableDetectMarkerMovement();
    this.enableRemovevertices();

    const bounds = new google.maps.LatLngBounds();
    bounds.extend(googleBBoxPoints[0]);
    bounds.extend(googleBBoxPoints[2]);

    if (paddingB) {
      const mapDiv = this.map.getDiv();

      const padding = {
        bottom: mapDiv.offsetHeight * 0.1,
        left: mapDiv.offsetWidth * 0.4,
        right: mapDiv.offsetWidth * 0.1,
        top: mapDiv.offsetHeight * 0.1,
      };
      this.map.fitBounds(bounds, padding);
    } else {
      this.map.fitBounds(bounds);
    }
  }

  public clearDrawing(): void {
    if (this.drawing.polygon && this.drawing.polygon.overlay) {
      this.isAreaSelected = false;
      // console.log('Area selected is off');
      this.drawing.polygon.overlay.setMap(null);
      this.drawing.polygon = null;
      this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
      if (this.areaInfoWindow) {
        this.areaInfoWindow.close();
      }
    }
  }
  public clearDrawingNew(): void {
    this.isAreaSelected = false;
    this.marker.setDraggable(false);
    this.polygon.setPaths([]);
    this.centerMarkerStart();
  }

  public showAreaInfoWindow(): void {
    if (this.areaInfoWindow) {
      this.areaInfoWindow.close();
    }
    const meta = this.getAreaMetaData();
    if (meta) {
      const content = `<div class="area-info-window">Area, ${meta.area} m<sup>2</sup></div>`;
      const center = this.getPolygonCenter(this.drawing.polygon.overlay);
      this.areaInfoWindow = new google.maps.InfoWindow({
        content: content,
        position: {lat: center.lat(), lng: center.lng()}
      });
      this.areaInfoWindow.open(this.map);
    }
  }

  public getMarkerTurf(): any {
    return point([this.marker.getPosition().lng(), this.marker.getPosition().lat()]);
  }
  public getMarkerPosition(): any {
    return {lat: this.marker.getPosition().lat(), lng: this.marker.getPosition().lng()};
  }
  public getCenterOfMass(): any {
    const polyTurf = this.getPolygonTurf();
    const centerOfMassTurf = centerOfMass(polyTurf);

    return({lat: centerOfMassTurf.geometry.coordinates[1], lng: centerOfMassTurf.geometry.coordinates[0]});
  }
  public getPolygonPath(): any {
    return this.polygon.getPath().getArray().map(x => {
      return { lat: x.lat(), lng: x.lng() };
    });
  }

  public setArea(newArea: number): void {
    this.polygonArea = newArea;
  }

  public getArea(): number {
    return Number(this.polygonArea);
  }

  public getPolygonTurf(): any {
    const polygonTurf = this.polygon.getPath().getArray().map(x => {
      return [ x.lng(), x.lat() ];
    });
    polygonTurf.push(polygonTurf[0]);
    return polygon([polygonTurf]);
  }

  public getPolygonCenter(overlay: any): any {
    // console.log('getPolygonCenter');
    // console.log(this.drawing.polygon.overlay);
    const poly = overlay;
    let lowx,
      highx,
      lowy,
      highy;
    const lats = [],
      lngs = [],
      vertices = poly.getPath();

    for (let i = 0; i < vertices.length; i++) {
      lngs.push(vertices.getAt(i).lng());
      lats.push(vertices.getAt(i).lat());
    }

    lats.sort();
    lngs.sort();
    lowx = lats[0];
    highx = lats[vertices.length - 1];
    lowy = lngs[0];
    highy = lngs[vertices.length - 1];
    const center_x = lowx + ((highx - lowx) / 2);
    const center_y = lowy + ((highy - lowy) / 2);
    this.isAreaSelected = true;
    // console.log('Area selected is on');
    return (new google.maps.LatLng(center_x, center_y));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (isPlatformBrowser(this.platformId)) {
      if ((changes['lat'] && changes['lng']) && (changes['lat'].currentValue && changes['lng'].currentValue)) {
        if (!this.init) {
          this.initMap();
        }
        this.setCenter(this.lat, this.lng);
        if (this.allCoords) {
          if (this.allCoords.length <= 1) {
            this.addMarker(this.lat, this.lng);
          } else {
            this.drawPolygon(this.allCoords);
          }
        }
      }
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.initMap();
      setTimeout(() => {
        google.maps.event.trigger(this.map, 'resize');
        this.setCenter(this.lat, this.lng);
      }, 0);
    }
  }

  ngOnDestroy(): void {
    this.drawing.listeners.forEach((l) => {
      google.maps.event.removeListener(l);
    });
  }
}
