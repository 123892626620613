import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { DialogService } from './../../../common/components/dialog/dialog.service';
import {FormControl, FormGroup} from '@angular/forms';
import {GoogleMapDirective} from '../../../common/components/wrappers/directives/google.map.directive';
declare let google: any;

@Component({
  selector: 'visitor-address-selection',
  templateUrl: './address-selection.component.html'
})
export class AddressSelectionComponent implements OnInit, AfterViewInit {

  @ViewChild(GoogleMapDirective, { static: true }) public mapRef: GoogleMapDirective;
  @Output() public sendData: EventEmitter<any> = new EventEmitter();

  public searchForm: FormGroup;
  public inputLatLng: Boolean;
  public selection: any = {
    address: null,
    message: null
  };
  showHelp = true;
  showHelpFadeOut = false;
  private polygonSet = false;

  @Input() public selectedAddress: string;
  @Input() public selectedArea: number;
  @Input() public selectedCoordinates: any[] = [];

  /* Munich center */
  public mapParams = {
    isAreaSelected: false,
    options: {
      mapTypeId: google.maps.MapTypeId.HYBRID,
      streetViewControl: false,
      disableDefaultUI: true,
      tilt: 0,
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER
      }
    }
  };
  constructor(
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.searchForm = new FormGroup({
      address: new FormControl('')
    });

    if (this.selectedAddress) {
      this.showHelp = false;
      if (this.selectedAddress !== 'KML-File') {
        document.querySelector<HTMLInputElement>('.inputAddress input').value = this.selectedAddress;
      }
      this.selection.address = this.selectedAddress;
    }
    if (this.selectedArea) {
      this.showHelp = false;
      this.mapRef.setArea(this.selectedArea);
    }
  }

  ngAfterViewInit() {
    if (this.mapRef) {
      this.mapRef.enableCenterZoom(document.querySelector('.map-wrapper'));
      if (this.selectedCoordinates) {
        if (this.selectedCoordinates.length === 1) {
          this.mapRef.centerMarkerStart();
          setTimeout(() => {
            this.mapRef.setCenter(this.selectedCoordinates[0].lat, this.selectedCoordinates[0].lng);
          }, 100);
        } else {
          this.mapRef.centerMarkerEnd();
          this.mapRef.setMarkerPosition(this.selectedCoordinates[0].lat, this.selectedCoordinates[0].lng);
          this.mapRef.makeMarkerVisible(false);
          this.mapRef.drawPolygon(this.selectedCoordinates);
          this.mapRef.makePolygonEditable();
          setTimeout(() => {
            this.mapRef.setCenter(this.selectedCoordinates[0].lat, this.selectedCoordinates[0].lng);
            this.mapRef.isAreaSelected = true;
          }, 100);
        }
      } else {
        this.mapRef.centerMarkerStart();
      }
    }
  }

  public animateHelper(): void {
    if (this.showHelp) {
      this.showHelpFadeOut = true;
    }
  }

  private getValue(target: EventTarget): string {
    return (target as HTMLInputElement).value;
  }

  public onAddressChange(event: any): void {
    this.selection.message = null;
    this.inputLatLng = false;
    const inputString = this.getValue(event.target);

    const regexpDD = '^\\s*(-?\\d+(.?,?\\d*)?)\\s*[,;]?\\s*(-?\\d+(.?,?\\d*)?)\\s*$';
    const regexpDMS = `^\\s*[\\d]{1,3}\\s*°\\s*[\\d]{1,2}\\s*'\\s*[\\d]*[.,]?[\\d]*"\\s*[NnEeSsWw]{1}\\s*[,;]?\\s*[\\d]{1,3}\\s*°\\s*[\\d]{1,3}\\s*'\\s*[\\d]*[.,]?[\\d]*"\\s*[NnEeSsWw]{1}\\s*$`;
    const regexpDMSns = `[\\d]*\\s*°\\s*[\\d]{1,2}\\s*'\\s*[\\d]*[.,]?[\\d]*"\\s*[NnSs]{1}`;
    const regexpDMSew = `[\\d]*\\s*°\\s*[\\d]{1,2}\\s*'\\s*[\\d]*[.,]?[\\d]*"\\s*[EeWw]{1}`;

    if (new RegExp(regexpDMS).test(inputString)) {
      const ns = this.mapRef.dmsd2dd(new RegExp(regexpDMSns).exec(inputString)[0].replace(/\s/g, ''));
      const ew = this.mapRef.dmsd2dd(new RegExp(regexpDMSew).exec(inputString)[0].replace(/\s/g, ''));
      if (ns >= -90 && ns <= 90 && ew >= -180 && ew <= 180) {
        this.mapRef.clearDrawingNew();
        this.mapRef.isAreaSelected = false;
        this.mapRef.makeMarkerVisible(true);
        this.mapRef.polygonArea = null;
        this.mapRef.setCenter(ns, ew);
        this.mapRef.setZoom(17);
        this.selection.address = inputString;
      }
    } else if (new RegExp(regexpDD).test(inputString)) {
      const ns = parseFloat(inputString.split(new RegExp('\\s*[,;]?\\s').exec(inputString)[0])[0].replace(',', '.'));
      const ew = parseFloat(inputString.split(new RegExp('\\s*[,;]?\\s').exec(inputString)[0])[1].replace(',', '.'));
      if (ns >= -90 && ns <= 90 && ew >= -180 && ew <= 180) {
        this.mapRef.clearDrawingNew();
        this.mapRef.isAreaSelected = false;
        this.mapRef.makeMarkerVisible(true);
        this.mapRef.polygonArea = null;
        this.mapRef.setCenter(ns, ew);
        this.mapRef.setZoom(17);
        this.selection.address = inputString;
      }
    }
  }
  public onAddressSelect(event: any): void {
    switch (event.place.address_components[0].types[0]) {
      case 'street_number': {
        this.selection.message = null;
        break;
      }
      case 'plus_code': {
        this.selection.message = null;
        break;
      }
      case 'route': {
        this.selection.message = 'no-number';
        break;
      }
      case 'locality': {
        this.selection.message = 'no-street';
        break;
      }
      default: {
        this.selection.message = 'no-city';
        break;
      }
    }
    this.mapRef.clearDrawingNew();
    this.selection.address = event.place.formatted_address;
    this.mapRef.isAreaSelected = false;
    this.mapRef.polygonArea = null;
    this.mapRef.setCenter(event.place.geometry.location.lat(), event.place.geometry.location.lng());
    this.mapRef.makeMarkerVisible(true);
    this.mapRef.setZoom(17);
  }

  public fileChangeEvent(input): void {
    const file = input.target.files[0];
    const fileReader = new FileReader();
    fileReader.onload = () => {
      this.mapRef.parseXML(fileReader.result).then((coordinates) => {
        document.querySelector<HTMLInputElement>('.inputAddress input').value = '';
        if (coordinates === false) {
          this.mapRef.centerMarkerStart();
          this.mapRef.isAreaSelected = false;
          this.mapRef.removePolygon();
          this.polygonSet = false;
          this.selection.address = '';
          this.selection.message = 'KML-error';
        } else {
          this.mapRef.makeMarkerVisible(false);
          this.mapRef.setPolygonPath(coordinates);
          this.mapRef.centerMarkerEnd();
          this.mapRef.makePolygonEditable();
          this.mapRef.isAreaSelected = true;
          this.polygonSet = true;
          this.selection.message = null;
          const centerCoordinates = this.mapRef.getCenterOfMass();
          this.selection.address = `${centerCoordinates.lat}, ${centerCoordinates.lng}`;
          this.mapRef.fitBounds();
          this.animateHelper();
        }
      });
    };
    if (file !== undefined) {
      fileReader.readAsText(file);
    }
  }

  public placeMarker(): void {
    this.mapRef.centerMarkerEnd();
    this.mapRef.placePolygon();
    this.polygonSet = true;
  }

  public resetMarker(): void {
    this.mapRef.centerMarkerStart();
    this.mapRef.removePolygon();
    this.polygonSet = false;
  }

  public confirmSelectionAOI(): void {
    if (this.mapRef.getArea() !== 2500) {
      this.sendData.emit({
        address: this.selection.address,
        latlng: this.mapRef.getPolygonPath(),
        selectedArea: this.mapRef.getArea()
      });
    } else {
      this.dialogService.showDialog('order.address-selection.skip-marking-AOI-title',
        'order.address-selection.skip-marking-AOI-message', false, '', true).subscribe(res => {
        if (res) {
          this.sendData.emit({
            address: this.selection.address,
            latlng: this.mapRef.getPolygonPath(),
            selectedArea: this.mapRef.getArea()
          });
        }
      });
    }
  }

  public confirmSelectionPOI(): void {

    this.dialogService.showDialog('order.address-selection.skip-marking-AOI-title',
      'order.address-selection.skip-marking-AOI-message', false, '', true).subscribe(res => {
      if (res) {
        this.sendData.emit({
          address: this.selection.address,
          latlng: [this.mapRef.getMarkerPosition()]
        });
      }
    });
  }

}
