import {Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'widget-recent-login',
  templateUrl: './recentLogin.html',
})
export class RecentLoginComponent implements OnInit {
  @Input() data: Array<any> = [];

  ngOnInit(): void {
    this.data = this.data.map(d => {
      return d.created_at = new Date(d.created_at);
    });
  }
}
