import { Component, OnInit, ViewChild } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap';
import { Routes, RouterModule } from '@angular/router';
import * as moment from 'moment';

import { countryList } from '../../../common/services/util';
import { environment } from '../../../../environments/environment';
import { Validator } from '../../services/validator';
import { LoadingComponent } from './../loading/loading.component';
import { UiLanguageService } from '../../../common/services/ui-language.service';
import { Util } from '../../services/api/util';

@Component({
  selector: 'common-immoviewer-erfassung',
  templateUrl: './immoviewer-erfassung.html',
  styleUrls: ['./immoviewer-erfassung.scss']
})

export class ImmoviewerErfassungComponent implements OnInit {

  @ViewChild(LoadingComponent, { static: false }) loading: LoadingComponent;
  @ViewChild('mediaModal', { static: false }) public mediaModal: ModalDirective;
    public submitted: boolean;
    public form: FormGroup;
    public env: any = environment;
    public countries: Array<any> = countryList;

    constructor(
        private adapter: DateAdapter<any>,
        private uiLanguageService: UiLanguageService,
        private service: Util) {
      const currentUiLanguage = this.uiLanguageService.getUiLanguage();
      this.adapter.setLocale(currentUiLanguage);
    }

    ngOnInit(): void {
      this.form = new FormGroup({
        address: new FormControl('', Validators.required),
        billing_address: new FormControl('', Validators.required),
        city: new FormControl(''),
        company: new FormControl('', Validators.required),
        country: new FormControl(''),
        datatoc: new FormControl('', Validators.required),
        email: new FormControl('', [Validator.mail, Validators.required]),
        first_name: new FormControl('', Validators.required),
        last_name: new FormControl('', Validators.required),
        package: new FormControl('', Validators.required),
        phone: new FormControl('', Validators.required),
        province: new FormControl(''),
        street: new FormControl(''),
        toc: new FormControl('', Validators.required),
        type_of_building: new FormControl('', Validators.required),
        zip_code: new FormControl(''),
      });
    }

    public onAddressSelect(selected: any): void {
      this.form.get('zip_code').setValue(selected.getPlaceComponent(selected.place, 'postal_code'));
      this.form.get('city').setValue(selected.getPlaceComponent(selected.place, 'locality'));
      this.form.get('province').setValue(selected.getPlaceComponent(selected.place, 'administrative_area_level_1'));
      this.form.get('street').setValue(selected.getPlaceComponent(selected.place, 'route')
      + ' ' + (selected.getPlaceComponent(selected.place, 'street_number')));
      const countryName = selected.getPlaceComponent(selected.place, 'country');
      const country = countryList.find(c => c.name === countryName);
      if (country) {
        this.form.get('country').setValue(country.iso);
      }
    }

    public onSubmit(): void {
      if (this.form.valid) {
        this.submitted = true;
        this.loading.toggle();
        const payload = this.form.getRawValue();
        this.service.immoviewerErfassung(payload).subscribe(res => {
          this.submitted = false;
          this.loading.toggle();
          this.mediaModal.show();
        }, e => {
          console.log('Error while sending contact request ', e);
        });
       }
    }
}

