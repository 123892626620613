import {Component, ViewChild, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {ModalDirective} from 'ngx-bootstrap';

import {GoogleMapDirective} from '../../../common/components/wrappers/directives/google.map.directive';

declare let google: any;

@Component({
  selector: 'common-map-selection',
  templateUrl: './selection.html'
})
export class MapSelectionComponent implements OnInit {

  public showHelpTip = true;
  public searchForm: FormGroup;
  public selection: any = {
    address: null
  };

  /* Munich center */
  public mapParams = {
    lat: 48.137154,
    lng: 11.576124,
    zoom: 3,
    drawing: true,
    isAreaSelected: false,
    options: {
      mapTypeId: google.maps.MapTypeId.HYBRID,
      streetViewControl: false,
      disableDefaultUI: true,
      tilt: 0,
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_CENTER
      },
    }
  };

  public drawingParams = {
    drawingMode: google.maps.drawing.OverlayType.POLYGON,
    drawingControl: false,
    polygonOptions: {
      fillColor: '#F4A624',
      fillOpacity: 0.5,
      strokeWeight: 6,
      strokeColor: '#F4A624',
      clickable: true,
      editable: true,
      zIndex: 1
    }
  };

  @ViewChild('selectionModal', { static: false }) public selectionModal: ModalDirective;
  @ViewChild(GoogleMapDirective, { static: true}) public mapRef: GoogleMapDirective;

  @Output() public popData: EventEmitter<any> = new EventEmitter();
  @Input() private show: boolean;
  @Input() private hide: boolean;

  ngOnInit(): void {
    this.searchForm = new FormGroup({
      address: new FormControl('')
    });
  }

  public openModal(): void {
    this.selectionModal.show();
  }

  public closeModal(): void {
    // console.log('Selection Modal hide');
    this.selectionModal.hide();
  }

  public onModalOpen(): void {
    this.mapRef.triggerResize();
  }

  public clearDrawing(): void {
    this.mapRef.clearDrawing();
  }

  public confirmSelection(): void {
    this.popData.emit({
      selection: this.selection,
      areaMetaData: this.mapRef.getAreaMetaData()
    });
    if (!this.selection || !this.selection.address) {
      // console.log('No address entered, please select address');
    } else {
      this.selectionModal.hide();
    }
  }

  public onAddressSelect(event: any): void {
    this.clearDrawing();
    this.selection.address = event.place.formatted_address;
    this.mapRef.setZoom(19);
    this.mapRef.setCenter(event.place.geometry.location.lat(), event.place.geometry.location.lng());
  }

  public cancelHelpTip(): void {
    this.showHelpTip = false;
  }
}
