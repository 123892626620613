import {ActivatedRoute, Router} from '@angular/router';
import {Component, OnDestroy} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {Location} from '@angular/common';

@Component({
  selector: 'common-params-handler',
  template: ''
})
export class ParamHandlerComponent implements OnDestroy {

  private paramsListener: Subscription;
  public params: Observable<any>;

  constructor(protected route: ActivatedRoute, protected location: Location, protected router: Router) {
    this.paramsListener = this.route.params.subscribe(params => {
      this.params = new Observable(ob => {
        ob.next(params);
      });
    });
  }

  public toNotFound(): void {
    this.router.navigateByUrl('/404');
  }

  ngOnDestroy(): void {
    this.paramsListener.unsubscribe();
  }

  public back(): void {
    this.location.back();
  }

}
