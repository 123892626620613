import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';

import {GoogleMapDirective} from '../../../common/components/wrappers/directives/google.map.directive';

declare let google: any;

@Component({
  selector: 'visitor-address-summary',
  templateUrl: './address-summary.component.html'
})
export class AddressSummaryComponent implements OnInit, AfterViewInit {

  @ViewChild(GoogleMapDirective, { static: true }) public mapRef: GoogleMapDirective;

  @Input() public selectedAddress: string;
  @Input() public selectedArea: string;
  @Input() public selectedCoordinates: any[] = [];

  @Output() public onReset: EventEmitter<any> = new EventEmitter();


  /* Munich center */
  public mapParams = {
    zoom: 17,
    drawing: true,
    isAreaSelected: false,
    options: {
      mapTypeId: google.maps.MapTypeId.HYBRID,
      streetViewControl: false,
      disableDefaultUI: true,
      tilt: 0,
      zoomControl: false,
      zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_CENTER
      },
    }
  };

  public drawingParams = {
    drawingMode: google.maps.drawing.OverlayType.POLYGON,
    drawingControl: false,
    polygonOptions: {
      fillColor: '#F4A624',
      fillOpacity: 0.5,
      strokeWeight: 6,
      strokeColor: '#F4A624',
      clickable: true,
      editable: true,
      zIndex: 1
    }
  };

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.mapRef) {
    }
  }

  public resetSelection(event: any): void {
    // console.log('clicking reset...');
    // console.log(this.onReset);
    this.onReset.emit(event);
  }

}
