import { Component } from '@angular/core';

@Component({
  selector: 'common-fab-contact',
  templateUrl: './fab-contact.html',
})
export class FabContactComponent {
  public showMail: boolean;
  public showNumber: boolean;

  constructor() {}

  showPhoneNumber(event: any): void {
    this.showNumber = !this.showNumber;
  }
  showMailIcon(event: any): void {
    this.showMail = !this.showMail;
  }
}
