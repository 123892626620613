import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { UiLanguageService } from '../../services/ui-language.service';

export interface Award {
  order: number;
  language: string;
  title: string;
  logoSource: string;
  text: string;
  name: string;
  position: string;
}

@Component({
  selector: 'app-awards',
  templateUrl: './awards.component.html',
  styleUrls: ['./awards.component.scss']
})
export class AwardsComponent {

  public currentAward: Award;
  private currentAwardIndex = 0;
  private awards: Award[] = [];

  constructor(private translate: TranslateService, private uiLanguageService: UiLanguageService) {
    this.getAwards();
    translate.onLangChange.subscribe(next => {
      this.getAwards();
    });
  }

  public slideLeft(event: any): void {
    if (this.currentAwardIndex === 0) {
      this.currentAwardIndex = this.awards.length - 1;
    } else {
      this.currentAwardIndex--;
    }
    this.currentAward = this.awards[this.currentAwardIndex];
  }

  public slideRight(event: any): void {
    if (this.currentAwardIndex === this.awards.length - 1) {
      this.currentAwardIndex = 0;
    } else {
      this.currentAwardIndex++;
    }
    this.currentAward = this.awards[this.currentAwardIndex];
  }

  private getAwards(): void {
    this.translate.get('awards').subscribe((res: Array<Award>) => {
      this.awards = res;
      this.currentAward = this.awards[this.currentAwardIndex];
      console.log(this.currentAward);
    });

  }

}
