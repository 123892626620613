import {Component, Input} from '@angular/core';

@Component({
  selector: 'common-complete-profile',
  templateUrl: './completeProfile.html'
})
export class CompleteProfileComponent {

  @Input() actions: Array<any> = [];
  @Input() percents = 0;
  @Input() profileLink: string;
  @Input() context: string;
}
