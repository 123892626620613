import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'number'
})
export class DynamicDecimalPipe implements PipeTransform {

  constructor(private translate: TranslateService) {

  }

  public transform(value: any, digits: string = null): any {
    const ngPipe = new DecimalPipe(this.translate.currentLang);
    return ngPipe.transform(value, digits);
  }

}
