import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { IndustryDetails, Util } from '../../../../common/services/api/util';

interface Link {
  label: string;
  href: string;
}

@Component({
  selector: 'pilot-footer',
  templateUrl: './footer.html'
})
export class FooterComponent {

  public allIndustries: IndustryDetails[];

  public staticLinks: Link[] = [
    {
      label: 'about',
      href: '/about'
    },
    {
      label: 'contact',
      href: '/contact'
    },
    {
      label: 'press',
      href: '/press'
    },
    {
      label: 'terms',
      href: '/legal/terms'
    },
    {
      label: 'imprint',
      href: '/legal/imprint'
    },
    {
      label: 'privacy',
      href: '/legal/privacy'
    }
  ];

  constructor(
    private router: Router,
    private utilService: Util
  ) {
      this.allIndustries = this.utilService.allIndustries;
  }

  public navigateToHome(): void {
    const element = document.querySelector('body');
    if (element) {
      element.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
    if (this.router.url.startsWith('/pilot/')) {
      this.router.navigateByUrl('/pilot/dashboard');
    } else if (this.router.url.startsWith('/client/')) {
      this.router.navigateByUrl('/client/dashboard');
    } else {
      this.router.navigateByUrl('/');
    }
  }
}
