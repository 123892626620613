import {Component, Inject} from '@angular/core';
import {FormGroup} from '@angular/forms';

import {NotificationsServiceInterface} from '../../../common/interfaces/service';

@Component({
  selector: 'base-notification-settings',
  template: ''
})
export class NotificationSettingsComponent {

  private valuesFilled = false;
  private delay: any = null;

  constructor(@Inject('NotificationsServiceInterface') protected service: NotificationsServiceInterface) {
  }

  protected initNotificationSettings(form: FormGroup, prefix: string): void {
    this.service.getNotificationsSettings().subscribe(res => {
      this.fillExistingValues(res, prefix, form);
    }, e => {
      // console.log('error while retrieving email settings', e);
    });
  }

  private fillExistingValues(settings: any, prefix: string, form: FormGroup): void {
    Object.keys(settings).forEach(key => {
      if (key.startsWith(prefix)) {
        const control = form.controls[key];
        if (control) {
          control.setValue(settings[key] === 1);
        }
      }
    });
    this.valuesFilled = true;
  }

  protected updateSettings(form: FormGroup): void {
    if (this.valuesFilled) {
      if (this.delay) {
        clearTimeout(this.delay);
      }
      this.delay = setTimeout(() => {
        const payload = form.getRawValue();
        Object.keys(payload).forEach(p => {
          payload[p] = payload[p] ? 1 : 0;
        });
        this.service.updateNotificationsSettings(payload).subscribe(res => {
          // console.log('settings are updated');
        }, e => {
          // console.log('error while updating notifications settings');
        });
      }, 1000);
    }
  }
}
