import {Component, forwardRef, Inject} from '@angular/core';

import {BaseDetailComponent} from '../../../../../common/components/jobs/details/details.component';

declare let moment: any;

@Component({
  selector: 'base-job-details-overview',
  template: ''
})
export class BaseJobOverviewComponent {

  public pilotLocation: Array<any> = [];
  public jobLocation: Array<any> = [];
  public menu: Array<any> = [];
  public deadline: string;
  public job: any = {};

  constructor(@Inject(forwardRef(() => Component)) protected parent: BaseDetailComponent) {
      this.parent['job'].subscribe(res => {
        this.jobReady(res);
      });
  }

  get refFiles(): Array<any> {
    const docs = this.job.client_docs;
    return docs ? (docs.order_funnel ? docs.order_funnel : []) : [];
  }

  get docs(): any {
    return this.job.client_docs ? this.job.client_docs : {};
  }

  get allCoords(): any {
    return this.jobLocation;
  }

  get coordinates(): any {
    return this.jobLocation[0] || null;
  }

  public jobReady(job): void {
    this.initJob(job);
  }

  public initJob(job: any): void {
    this.job = job;
    this.jobLocation = [].concat(this.job.coordinates);
    this.deadline = moment(moment(new Date())).to(this.job.deadline);
  }
}
