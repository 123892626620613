import {ActivatedRoute, Router} from '@angular/router';
import {Component, Inject} from '@angular/core';
import {Location} from '@angular/common';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

import {BaseJobServiceInterface} from '../../../../common/interfaces/service';
import {ParamHandlerComponent} from '../../abstract/param.handler.component';

const metaData: any = {
  selector: '',
  template: ''
};

@Component(metaData)
export class BaseDetailComponent extends ParamHandlerComponent {

  protected isPrevious = false;
  public job: Observable<any>;

  constructor(
    protected route: ActivatedRoute,
    protected location: Location,
    protected router: Router, @Inject('BaseJobServiceInterface')
    protected service: BaseJobServiceInterface) {

    super(route, location, router);

    // moved this subscription from onInit to the constructor so solve bug FF-2150
    // if it causes other problems please review the fix
    this.params.subscribe(params => {
      if (params['id']) {
        this.job = this.service.getJobById(parseInt(params['id'], 10), this.isPrevious).pipe(map(res => {
          const mutated = res;
          mutated.coordinates = JSON.parse(mutated.coordinates);
          return mutated;
        }));
      } else {
        this.toNotFound();
      }
    });
  }
}
