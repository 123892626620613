import { Component, Inject, Optional } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})

export class DialogComponent {
  formGroup: FormGroup;

  constructor(
      public dialogRef: MatDialogRef<DialogComponent>,
      @Optional() @Inject(MAT_DIALOG_DATA)
      public data: {
        confirm?: boolean,
        img?: string,
        input?: boolean,
        message?: string,
        title?: string
      },
      private formBuilder: FormBuilder
  ) {
    this.formGroup = this.formBuilder.group({
      input: new FormControl('', [
        Validators.required,
        Validators.maxLength(256)
      ])
    });
   }

   get input(): AbstractControl { return this.formGroup.get('input'); }

   save() {
    const {value, valid} = this.formGroup.get('input');
    if (valid) {
        this.dialogRef.close(value);
    }
}

}
