
import {share} from 'rxjs/operators';

import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {AbstractUser} from './abstract.user';
import {environment} from '../../../../environments/environment';
import {Session} from '../util';

@Injectable()
export class Client extends AbstractUser {

  public authPromise: Promise<boolean>;

  public getUserType(): 'pilot' | 'client' {
    return 'client';
  }

  constructor(http: HttpClient, private sess: Session) {
    super(http);

    this.authPromise = new Promise((resolve) => {
      if (this.sess.get(this.sess.keys.ACCESS_TOKEN) && this.sess.get(this.sess.keys.ACCESS_TOKEN)[0] === 'c') {
        this.initFromObservable(
          this.http.get(environment.apiBaseUrl + environment.endpoints.client.getProfile)
        ).subscribe(
            () => {
              resolve(true);
            },
            () => {
              resolve(false);
            }
          );
      } else {
        resolve(false);
      }
    });
  }

  private initFromObservable(ob: Observable<any>) {
    ob.subscribe(
      (res) => {
        const data = res;
        this.resourceUrl = environment.apiBaseUrl + environment.endpoints.client.updateProfile + '/' + data.client_id;
        if (data.token) {
          this.sess.set(this.sess.keys.ACCESS_TOKEN, data.token);
          this.sess.set(this.sess.keys.TOKEN_V3, data.token_v3);
        }
        this.stream.next({
          type: 'INIT',
          data: data
        });
      },
      (e) => {
        // todo handle error here
        // console.log('error while login ', e);
      }
    );
    return ob;
  }

  public auth(email: string, password: string) {
    return this.initFromObservable(
      this.http.post(environment.apiBaseUrl + environment.endpoints.client.getProfile, null, {
        headers: new HttpHeaders({'auth-token': btoa(unescape(encodeURIComponent(email + ':' + password)))})
      }).pipe(share())
    );
  }

  public logout() {
    this.sess.set(this.sess.keys.ACCESS_TOKEN, null);
    this.sess.set(this.sess.keys.TOKEN_V3, null);
    this.flush();
  }

  getProfile(): Observable<any> {
    return this.http.get(environment.apiBaseUrl + environment.endpoints.client.getProfile);
  }

  public getNotificationsSettings(): Observable<any> {
    return this.http.get(environment.apiBaseUrl + environment.endpoints.client.notificationsSettings);
  }

  public updateNotificationsSettings(payload: any): Observable<any> {
    return this.http.post(environment.apiBaseUrl + environment.endpoints.client.notificationsSettings, payload);
  }

  public changePassword(payload: any): Observable<any> {
    return this.http.post(environment.apiBaseUrl + environment.endpoints.client.changePassword, payload);
  }

  public deleteAccount(): Observable<any> {
    return this.http.delete(environment.apiBaseUrl + environment.endpoints.client.deleteAccount);
  }

  public requestNewPassword(email: string): Observable<any> {
    const params: HttpParams = new HttpParams()
        .set('email', email)
        .set('version', '2'); // tmp just to not break old website
    return this.http.get(environment.apiBaseUrl + environment.endpoints.client.newPassRequest, {
      params: params
    });
  }

  public changePasswordWithToken(payload: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders()
        .append('reset-token', payload.token);
    return this.http.post(environment.apiBaseUrl + environment.endpoints.client.newPassRequest, payload, {
      headers: headers
    });
  }

  public signup(payload: any): Observable<any> {
    const url = environment.apiBaseUrl + environment.endpoints.client.register;
    return this.initFromObservable(
      this.http.post(url, payload).pipe(share())
    );
  }

  public getJobById(id: number): Observable<any> {
    return this.http.get(environment.apiBaseUrl + environment.endpoints.client.getJobById + '/' + id);
  }

  public getActiveJobs(): Observable<any> {
    return this.http.get(environment.apiBaseUrl + environment.endpoints.client.activeJobs);
  }

  public getConversationOfOrder(orderId: number, pilotId?: number): Observable<any> {
    const params: HttpParams = new HttpParams()
        .set('expand', 'messages')
        .set('pilot_id', pilotId.toString(10));
    return this.http.get(environment.apiBaseUrl + environment.endpoints.client.getConversationOfOrder + '/' + orderId, {
      params: params,
    });
  }

  public getPreviousJobs(): Observable<any> {
    return this.http.get(environment.apiBaseUrl + environment.endpoints.client.previousJobs);
  }

  public getOrderOffers(orderId: number):  Observable<any> {
    const params: HttpParams = new HttpParams()
        .set('expand', 'offersByPilot');
    return this.http.get(environment.apiBaseUrl + environment.endpoints.client.orderOffers + '/' + orderId, {
      params: params
    });
  }

  public postMessageToOrderConversation(orderId: number, payload: any):  Observable<any> {
    return this.http.post(environment.apiBaseUrl + environment.endpoints.client.postMessageToOrderConversation + '/' + orderId, payload);
  }

  public chooseOffer(orderId: number, offerId: number, payload: any):  Observable<any> {
    return this.http.post(environment.apiBaseUrl + environment.endpoints.client.chooseOffer + '/' + orderId + '/choose_offer/' + offerId,
        payload);
  }

  public verifyOrder(payload: any, orderId: number) {
    return this.http.put(environment.apiBaseUrl + environment.endpoints.client.verifyOrder + '/' + orderId, payload);
  }

  public getRecentLogIns(): Observable<any> {
    return this.http.get(environment.apiBaseUrl + environment.endpoints.client.recentLogins);
  }

  public getRecentActivities(): Observable<any> {
    return this.http.get(environment.apiBaseUrl + environment.endpoints.client.recentActivities);
  }

  public completeProfileActions(): Observable<any> {
    return this.http.get(environment.apiBaseUrl + environment.endpoints.client.completeProfActions);
  }

  public markOrderAsDone(orderId: number): Observable<any> {
    return this.http.put(environment.apiBaseUrl + environment.endpoints.client.markOrderAsDone + '/' + orderId, null);
  }
}
