import { Component, OnInit, ViewChild } from '@angular/core';
import {ModalDirective} from 'ngx-bootstrap/modal';


@Component({
  selector: 'common-content-upload',
  templateUrl: './content-upload.component.html',
  styleUrls: ['./content-upload.component.scss']
})
export class ContentUploadComponent implements OnInit {

  @ViewChild('mediaModal', { static: false }) public mediaModal: ModalDirective;

  constructor() { }

  ngOnInit() {
  }

}
