import {Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'widget-view-more',
  templateUrl: './view-more.html',
})
export class ViewMoreComponent implements OnInit {

  public unLimited = false;
  private _limit = 0;

  @Input() text: string = null;
  @Input() limit = 10;

  ngOnInit(): void {
    this._limit = this.limit;
  }

  public showMore(): void {
    this.limit = -1;
    this.unLimited = true;
  }

  public showLess(): void {
    this.limit = this._limit;
    this.unLimited = false;
  }

  get extendable(): boolean {
    return this.text && this.text.length > this.limit;
  }
}
