import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {Router} from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import {CancelAccountInterface} from '../../../../common/interfaces/service';

@Component({
  selector: 'base-cancel-account',
  template: ''
})
export class BaseCancelAccountComponent {
  constructor(@Inject('CancelAccountInterface') private service: CancelAccountInterface,
      protected router: Router,
      @Inject(PLATFORM_ID) private platformId) {
  }

  public closeAccount(): void {
    this.service.deleteAccount().subscribe(res => {
      alert('Account was deleted!');
      if (isPlatformBrowser(this.platformId)) {
        localStorage.removeItem('access-token');
      }
      this.router.navigate(['/']);
    }, e => {
      // console.log('error while deleting account', e);
    });
  }
}
