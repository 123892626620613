import {Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import Action from './action';
import * as R from 'ramda';

@Injectable()
class AbstractObject {
  public stream: Subject<Action> = new Subject<Action>();

  private data: any = {};
  private callbacks: any = {
    'CHANGE': (newData) => {
      for (const key of Object.keys(newData)) {
        this.data[key] = newData[key];
      }
    }
  };

  public getData(): any {
    return R.clone(this.data);
  }

  public change(update: any): void {
    this.stream.next({ type: 'CHANGE', data: update });
  }

  private callback(action: Action): any {
    if (this.callbacks[action.type]) {
      this.callbacks[action.type](action.data, this);
    }
  }

  protected flush() {
    this.data = {};
    this.stream.next({ type: 'FLUSH', data: {} });
  }

  constructor(callbacks?: any) {
    if (callbacks) {
      for (const key of Object.keys(callbacks)) {
        this.callbacks[key] = callbacks[key];
      }
    }
    this.stream.subscribe({
      next: this.callback.bind(this)
    });
  }

}

export default AbstractObject;
