import {Component, Input, Inject, OnInit, Output, EventEmitter} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

import {AccountDataInterface} from '../../../../common/interfaces/service';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'base-invoicing',
  template: ''
})
export class BaseInvoicingComponent implements OnInit {

  public businessRequiredFields: Array<any> = [
    'billing_business_name'
  ];
  public entityTypes: any = {
    private: 'individual',
    business: 'company'
  };
  @Input() submitted = false;
  @Input() fields: Array<any> = [];
  @Input() invoicing: FormGroup;
  @Output() onEntityChange: EventEmitter<boolean> = new EventEmitter();
  public personalFieldsCount = 2;
  protected profile: any = {};
  private commonValidators: Array<any> = [Validators.maxLength(environment.apiValidationParams.strMaxlength)];

  constructor(@Inject('AccountDataInterface') protected service: AccountDataInterface) {
    this.profile = service.getData();
  }

  ngOnInit(): void {
    this.invoicing.get('billing_entity_type').setValue(this.profile.billing_entity_type || this.entityTypes.business);
    this.invoicing.get('billing_entity_type').valueChanges.subscribe(type => {
      if (type === this.entityTypes.business) {
        this.personalFieldsCount++;
      } else {
        this.personalFieldsCount--;
      }
      this.onEntityChange.emit(true);
    });
    if (this.profile.billing_entity_type === this.entityTypes.business) {
      this.personalFieldsCount++;
    }
  }

  public onAddressAutoComplete(selected: any): void {
    if (selected.place) {
      this.invoicing.get('billing_zip').setValue(selected.getPlaceComponent(selected.place, 'postal_code'));
      this.invoicing.get('billing_country').setValue(selected.getPlaceComponent(selected.place, 'country'));
      this.invoicing.get('billing_city').setValue(selected.getPlaceComponent(selected.place, 'locality'));
      this.invoicing.get('billing_address_line1').setValue(selected.getPlaceComponent(selected.place, 'route') +
      ' ' + selected.getPlaceComponent(selected.place, 'street_number'));
    }
  }

  public getControls(): Array<any> {
    if (this.isBusiness()) {
      this.invoicing.addControl('billing_business_name', new FormControl(this.profile.billing_business_name, [
        Validators.required
      ].concat(this.commonValidators)));
    } else {
      this.invoicing.removeControl('billing_business_name');
    }
    return this.fields.filter(x => !this.isBusiness() ? (x.key !== 'billing_business_name') : true);
  }

  public isGerman(): boolean {
    return this.profile.language === 'de';
  }

  public isHalfScreen(field: string): boolean {
    const halves = ['first_name', 'last_name', 'billing_zip', 'billing_city'];
    return halves.indexOf(field) > -1;
  }

  protected isBusiness(): boolean {
    return this.invoicing.get('billing_entity_type').value === this.entityTypes.business;
  }
}
