import {CommonModule} from '@angular/common';
// import {LocalizeRouterModule} from 'localize-router';
import {MatButtonModule, MatIconModule, MatInputModule, MatMenuModule} from '@angular/material';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ModalModule} from 'ngx-bootstrap/modal';
import {NgModule} from '@angular/core';
import {ProgressbarModule} from 'ngx-bootstrap/progressbar';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';

import {SharedPipesModule} from '../../common/pipes/shared.pipes.module';

/* Partials */
import {FooterComponent} from './partials/pilot-footer/footer.component';

/* widgets */
import {ActivityFeedComponent} from './widgets/activityFeed/activityFeed.component';
import {CompleteProfileComponent} from './widgets/completeProfile/completeProfile.component';
import {RecentActivitiesComponent} from './widgets/recentActivites/recentActivites.component';
import {RecentLoginComponent} from './widgets/recentLogin/recentLogin.component';

/* wrappers */
import {CharacterCounterDirective} from '../../common/components/wrappers/directives/character.counter.directive';
import {GoogleAutocompleteDirective} from './wrappers/directives/google.autocomplete.directive';
import {GoogleMapDirective} from './wrappers/directives/google.map.directive';
import {LocationDistanceComponent} from './widgets/locationDistance/location.distance.component';
import {TbSelectDirective} from './wrappers/directives/tbselect.directive';
import {TbSliderDirective} from './wrappers/directives/tbslider.directive';

/* feature */
import {AddressSummaryComponent} from '../../visitor/components/address-summary/address-summary.component';
import {AddressSelectionComponent} from '../../visitor/components/address-selection/address-selection.component';
import {AwardsComponent} from '../../common/components/awards/awards.component';
import {BaseBillingComponent} from '../../common/components/billing/billing.component';
import {BaseCancelAccountComponent} from './widgets/cancelAccount/cancelAccount.component';
import {BaseChangePasswordComponent} from './widgets/changePassword/changePassword.component';
import {BaseDetailComponent} from '../../common/components/jobs/details/details.component';
import {BaseEmailSettingsComponent} from './widgets/emailSettings/emailSettings.component';
import {BaseInvoicingComponent} from '../../common/components/billing/invoicing/invoicing.component';
import {BaseJobDetailsMenuComponent} from '../../common/components/jobs/details/menu/job.details.menu.component';
import {BaseJobOverviewComponent} from '../../common/components/jobs/details/overview/job.overview.component';
import {BaseJobsComponent} from '../../common/components/jobs/root/jobs.component';
import {BasePasswordChangeComponent} from '../../common/components/password/change/password.change.component';
import {BasePasswordResetComponent} from '../../common/components/password/reset/password.reset.component';
import {BaseSavingProcessComponent} from '../../common/components/abstract/saving.component';
import {BaseSignupComponent} from '../../common/components/signup/signup.component';
import {DatePickerComponent} from '../../common/components/widgets/datePicker/datePicker.component';
import {FabContactComponent} from '../../common/components/fab-contact/fab-contact.component';
import {GoogleAutocompleteComponent} from '../../common/components/google-autocomplete/autocomplete.component';
import {JobListComponent} from '../../common/components/abstract/jobList.component';
import {LabelValueComponent} from '../../visitor/components/label-value/label-value.component';
import {LoadingComponent} from '../../common/components/loading/loading.component';
import {MapSelectionComponent} from '../../common/components/map-selection/selection.component';
import {MapThumbnailComponent} from '../../visitor/components/map-thumbnail/map-thumbnail.component';
import {NotificationSettingsComponent} from '../../common/components/abstract/notification.settings.component';
import {PanelComponent} from '../../client/order/abstract/panel.component';
import {ParamHandlerComponent} from '../../common/components/abstract/param.handler.component';
import {SpecGroupComponent} from '../../pilot/account/components/specializations/spec-group/spec.group.component';
import {TestimonialsComponent} from '../../common/components/testimonials/testimonials.component';
import {VerifyPhoneComponent} from '../../common/components/verify-phone/verify-phone.component';
import {ViewMoreComponent} from '../../common/components/widgets/viewMore/view.more.component';
import {VisitorLoginComponent} from '../../visitor/components/visitor-login/visitor-login.component';
import {WidgetMapSelectionComponent} from '../../client/order/components/widget/components/map-selection/widget.map-selection.component';

const components: Array<any> = [
  ActivityFeedComponent,
  AwardsComponent,
  AddressSummaryComponent,
  AddressSelectionComponent,
  BaseBillingComponent,
  BaseCancelAccountComponent,
  BaseChangePasswordComponent,
  BaseDetailComponent,
  BaseEmailSettingsComponent,
  BaseInvoicingComponent,
  BaseJobDetailsMenuComponent,
  BaseJobOverviewComponent,
  BaseJobsComponent,
  BasePasswordChangeComponent,
  BasePasswordResetComponent,
  BaseSavingProcessComponent,
  BaseSignupComponent,
  CharacterCounterDirective,
  CompleteProfileComponent,
  DatePickerComponent,
  FabContactComponent,
  FooterComponent,
  GoogleAutocompleteComponent,
  GoogleAutocompleteDirective,
  GoogleMapDirective,
  JobListComponent,
  LabelValueComponent,
  LoadingComponent,
  LocationDistanceComponent,
  MapSelectionComponent,
  MapThumbnailComponent,
  NotificationSettingsComponent,
  PanelComponent,
  ParamHandlerComponent,
  RecentActivitiesComponent,
  RecentLoginComponent,
  SpecGroupComponent,
  TestimonialsComponent,
  TbSelectDirective,
  TbSliderDirective,
  VerifyPhoneComponent,
  ViewMoreComponent,
  VisitorLoginComponent,
  WidgetMapSelectionComponent,
];

@NgModule({
  imports: [
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    CommonModule,
    FormsModule,
    // LocalizeRouterModule.forChild([]),
    ModalModule.forRoot(),
    ProgressbarModule.forRoot(),
    ReactiveFormsModule,
    RouterModule,
    SharedPipesModule,
    TranslateModule.forChild(),
  ],
  declarations: [
    components
  ],
  exports: [
    components
  ]
})
export class SharedComponentsModule {
}
