// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.


import * as R from 'ramda';
import {commonEnvironment} from './environment.common';

const is_json = (x) => typeof x === 'object';

function mergeR(a, b) {
  const obj = R.clone(a);
  R.forEach(key => obj[key] = is_json(obj[key]) && is_json(b[key]) ? mergeR(obj[key] || {}, b[key]) : b[key], R.keys(b));
  return obj;
}

const patches = {
  production: false,
  // apiBaseUrl: 'http://local.fairfleet360.com', // for local 2.0 testing
  apiBaseUrl: 'https://dev.fairfleet360.com',
  // ff3BaseUrl: 'https://local.fairfleet.com', // for local 3.0 testing
  ff3BaseUrl: 'https://dev.fairfleet.com',
  localesPath: './assets/i18n/'
};

export const environment = mergeR(commonEnvironment, patches);
