// import {LocalizeRouterService} from 'localize-router';
import {Component, ViewChild} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material';
import {Router, NavigationStart} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

import {LoadingComponent} from '../../../common/components/loading/loading.component';
import {UiLanguageService} from '../../../common/services/ui-language.service';
import {Util} from '../../../common/services/api/util';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  @ViewChild(LoadingComponent, { static: false }) loading: LoadingComponent;

  public currentUiLanguage: string = this.uiLanguageService.getUiLanguage();

  constructor(public translate: TranslateService,
    private uiLanguageService: UiLanguageService,
    // public localizeService: LocalizeRouterService,
    private router: Router,
    private utilServices: Util,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer) {

    // translate.use(localizeService.parser.currentLang || 'en');
    this.currentUiLanguage = this.uiLanguageService.getUiLanguage();

    /* router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (localizeService.parser.currentLang && event.url.length > 3 && !this.doesUrlStartWithLocale(event.url)) {
          this.router.navigateByUrl('/' + localizeService.parser.currentLang + event.url);
        }
      }
    });*/

    // Register custom svg icons
    iconRegistry.addSvgIcon('fairfleet-logo',
    sanitizer.bypassSecurityTrustResourceUrl('https://fairfleet.com/a/favicon/safari-pinned-tab.svg'));
  }

  /* private doesUrlStartWithLocale(url: string) {
    const locales = this.localizeService.parser.locales;
    return locales.indexOf(url.slice(0, 2)) > -1 || locales.indexOf(url.slice(1, 3)) > -1 && url[0] === '/';
  }*/
}
