import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';

const moment = require('moment');

@Component({
  selector: 'widget-date-picker',
  templateUrl: './datePicker.html',
})
export class DatePickerComponent implements OnInit {

  public date: any = {
    months: [],
    years: [],
    days: []
  };
  private dateObj = new Date();
  public selectedDay: any = this.dateObj.getDate();
  private month: any = (this.dateObj.getMonth() + 1);
  public selectedMonth: string = this.month > 9 ? this.month.toString(10) : '0' + this.month;
  public selectedYear: any = this.dateObj.getFullYear();

  public form: FormGroup;

  @Input() control: FormControl;
  @Input() yearAhead = 10;

  ngOnInit(): void {
    this.initYears(new Date());
    this.initMoths();
    this.initDays(new Date());

    this.form = new FormGroup({
      day: new FormControl(this.selectedDay),
      month: new FormControl(this.selectedMonth),
      year: new FormControl(this.selectedYear),
    });

    this.form.get('month').valueChanges.subscribe(month => {
      const date = new Date();
      date.setMonth(parseInt(month, 10) - 1);
      date.setFullYear(this.form.get('year').value);
      this.initDays(date);
    });

    this.form.valueChanges.subscribe(changes => {
      const date = new Date();
      if (changes.day) {
        date.setDate(parseInt(changes.day, 10));
      }
      if (changes.month) {
        date.setMonth(parseInt(changes.month, 10) - 1);
      }
      if (changes.year) {
        date.setFullYear(parseInt(changes.year, 10));
      }
      this.control.setValue(date.toISOString());
    });
  }

  private initYears(date: Date): void {
    let current = date.getFullYear();
    this.date.years.push(current);
    for (let i = 0; i < this.yearAhead; i++) {
      this.date.years.push(++current);
    }
  }

  private initDays(date: Date): void {
    const days = moment(date).daysInMonth();
    if (days < parseInt(this.date.days[this.date.days - 1], 10)) {
      this.form.get('day').setValue('01');
    }
    this.date.days = [];
    for (let i = 1; i < days + 1; i++) {
      this.date.days.push(i > 9 ? i.toString(10) : '0' + i);
    }
  }

  private initMoths(): void {
    for (let i = 1; i < 13; i++) {
      this.date.months.push(i > 9 ? i.toString(10) : '0' + i);
    }
  }
}
