import {Broadcast} from '../../common/services/broadcast';

export class SaveButton {

  public broadcast: Broadcast;
  private timer: any = null;
  public states: any = {
    request: 0,
    active: 1,
    success: -1,
    error: -2
  };

  public state: number = this.states.active;

  constructor(public stateDelay = 1000) {
  }

  public isSuccess(): boolean {
    return this.state === this.states.success;
  }

  public isError(): boolean {
    return this.state === this.states.error;
  }

  public isRequest(): boolean {
    return this.state === this.states.request;
  }

  public turnToRequest(): void {
    this.state = this.states.request;
  }

  public receiveResponse(error: boolean = false): void {
    this.state = error ? this.states.error : this.states.success;
    if (!error) {
      this.broadcast.emit(this.broadcast.events.showSavePanel);
    }
    this.setTimer();
  }

  public clearTimer(): void {
    clearTimeout(this.timer);
  }

  public broadcaster(broadcaster: Broadcast): void {
    this.broadcast = broadcaster;
  }

  private setTimer(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      if (this.state === this.states.success) {
        this.broadcast.emit(this.broadcast.events.hideSavePanel);
      }
      this.state = this.states.active;
    }, this.stateDelay);
  }
}
