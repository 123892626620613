import {Component, OnInit, Inject} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';

import {NotificationSettingsComponent} from '../../abstract/notification.settings.component';
import {NotificationsServiceInterface} from '../../../../common/interfaces/service';

@Component({
  selector: 'base-email-settings',
  template: ''
})
export class BaseEmailSettingsComponent extends NotificationSettingsComponent implements OnInit {

  public emailSettingsForm: FormGroup;
  public fields: Array<string> = [
    'email_new_message',
    'email_request_for_quotes',
    'email_direct_job_requests',
    'email_general_promotions',
    'email_updates',
    'email_news',
    'email_inspiration',
  ];

  constructor(@Inject('NotificationsServiceInterface') protected settingsService: NotificationsServiceInterface) {
    super(settingsService);
  }

  ngOnInit(): void {
    const controls = {};
    this.fields.forEach(f => {
      controls[f] = new FormControl('');
    });
    this.emailSettingsForm = new FormGroup(controls);
    this.emailSettingsForm.valueChanges.subscribe(data => this.updateSettings(this.emailSettingsForm));
    this.initNotificationSettings(this.emailSettingsForm, 'email');
  }
}
