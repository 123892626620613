import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class UiLanguageService {
    availableUiLanguages: Array<string> = ['de', 'en'];
    defaultUiLanguage = 'de';

    private storage = isPlatformBrowser(this.platformId) ? localStorage : undefined;

    constructor(
      private translate: TranslateService,
      @Inject(PLATFORM_ID) private platformId) {
        // this language will be used as a fallback when a translation isn't found in the current language
        translate.setDefaultLang(this.defaultUiLanguage);
        // adding available localisation languages
        translate.addLangs(this.availableUiLanguages);

        const savedUserLanguage = this.getSavedUserLocale();
        const browserLanguage = translate.getBrowserLang();

        if (!savedUserLanguage && this.availableUiLanguages.indexOf(browserLanguage) !== -1) {
          // use language saved by currentUser
          translate.use(translate.getBrowserLang());
        } else if (!savedUserLanguage && this.availableUiLanguages.indexOf(browserLanguage) === -1) {
          // the lang to use, if the lang isn't available, it will use the current loader to get them
          translate.use(this.defaultUiLanguage);
        } else {
          // use browser language
          translate.use(savedUserLanguage);
        }
    }

    getUiLanguage(): string {
      return this.translate.currentLang;
    }

    setUiLanguage(locale: string): void {
      // this.router.navigateByUrl('/' + lngId + this.removeLocaleFromUrl(this.router.url));
      this.translate.use(locale);
      this.saveUserLocale(locale);
    }

    private getSavedUserLocale(): string {
      if (this.storage) {
        return this.storage['language'] || '';
      } else {
        return undefined;
      }
    }

    private saveUserLocale(language: string): void {
      if (this.storage) {
        try {
          this.storage['language'] = language;
        } catch (error) {
          // Safari will return an error when Private Browsing
          // console.warn('Could not write in local storage');
        }
      }
    }

}
