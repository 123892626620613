import { Component, OnInit, ViewChild } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap';
import { Routes, RouterModule } from '@angular/router';
import * as moment from 'moment';

import { countryList } from '../../../common/services/util';
import { environment } from '../../../../environments/environment';
import { Validator } from '../../services/validator';
import { LoadingComponent } from './../loading/loading.component';
import { UiLanguageService } from '../../../common/services/ui-language.service';
import { Util } from '../../services/api/util';

@Component({
  selector: 'common-homeday-external-order',
  templateUrl: './homeday-external-order.html',
  styleUrls: ['./homeday-external-order.scss']
})

export class HomedayExternalOrderComponent implements OnInit {

  @ViewChild(LoadingComponent, { static: false }) loading: LoadingComponent;
  @ViewChild('mediaModal', { static: false }) public mediaModal: ModalDirective;
  public submitted: boolean;
  public form: FormGroup;
  public env: any = environment;
  public countries: Array<any> = countryList;
  public packages: Array<any> = [];


  constructor(
      private adapter: DateAdapter<any>,
      private uiLanguageService: UiLanguageService,
      private utilService: Util) {
    const currentUiLanguage = this.uiLanguageService.getUiLanguage();
    this.adapter.setLocale(currentUiLanguage);
  }

  ngOnInit(): void {
    this.utilService.getServices('IMVRRE').subscribe(res => {
      res.forEach(s => {
        if (s.article_number.endsWith('.00')) { // Base package found
          this.packages.push({
            value: s.article_number,
            label: s.package_name,
            net_price: s.net_price,
            services: [{ // initialize also base service
              value: s.article_number,
              label: '',
              net_price: s.net_price,
            }]
          });
        } else { // extra services for the last package
          this.packages[this.packages.length - 1].services.push({
            value: s.article_number,
            label: s.package_name.split('+')[1].trim(),
            net_price: s.net_price,
          });
        }
      });
    }, e => {
      console.log('Error while sending request ', e);
    });

    this.form = new FormGroup({
      address: new FormControl('', Validators.required),
      additional_service: new FormControl('', Validators.required),
      city: new FormControl(''),
      country: new FormControl(''),
      datatoc: new FormControl('', Validators.required),
      flight_date: new FormControl('', Validators.required),
      full_name: new FormControl('', Validators.required),
      package: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.required),
      property_id: new FormControl('', Validators.required),
      province: new FormControl(''),
      street: new FormControl(''),
      toc: new FormControl('', Validators.required),
      type_of_building: new FormControl(''),
      zip_code: new FormControl(''),
    });
  }

  public onAddressSelect(selected: any): void {
    this.form.get('zip_code').setValue(selected.getPlaceComponent(selected.place, 'postal_code'));
    this.form.get('city').setValue(selected.getPlaceComponent(selected.place, 'locality'));
    this.form.get('province').setValue(selected.getPlaceComponent(selected.place, 'administrative_area_level_1'));
    this.form.get('street').setValue(selected.getPlaceComponent(selected.place, 'route')
    + ' ' + (selected.getPlaceComponent(selected.place, 'street_number')));
    const countryName = selected.getPlaceComponent(selected.place, 'country');
    const country = countryList.find(c => c.name === countryName);
    if (country) {
      this.form.get('country').setValue(country.iso);
    }
  }

  public onSubmit(): void {
    if (this.form.valid) {
      this.submitted = true;
      this.loading.toggle();
      const payload = this.form.getRawValue();
      payload.additional_service = payload.additional_service.value;
      payload.package = payload.package.label;
      console.log(payload);
      this.utilService.homedayExternalOrder(payload).subscribe(res => {
        this.submitted = false;
        this.loading.toggle();
        this.mediaModal.show();
      }, e => {
        console.log('Error while sending request ', e);
      });
      }
  }
}

