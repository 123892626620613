import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, size: number = 10): string {
    if (!value || size < 0) {
      return value;
    }
    return value.length > size ? ( value.substring(0, size) + '...') : value;
  }
}
