
import {filter} from 'rxjs/operators';
import {NavigationEnd, Router, ActivatedRoute} from '@angular/router';
import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'base-job-details-menu',
  template: ''
})
export class BaseJobDetailsMenuComponent {
  @Input() public isMobile = false;
  @Input() public job: any = {};

  private routeNamePrefix = 'jobs.overview.tabs.';
  public activeRouteName: string;
  public linkBack: string;
  public menu: Array<any> = [
    {
      link: 'overview',
      label: 'jobs.overview.tabs.overview'
    }
  ];

  constructor(protected router: Router, protected route: ActivatedRoute) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        let currentRoute = this.route.root;
        while (currentRoute.children[0] !== undefined) {
          currentRoute = currentRoute.children[0];
        }
        this.activeRouteName = this.routeNamePrefix + currentRoute.snapshot.data.name;
      });
  }

}
