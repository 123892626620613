
import {map} from 'rxjs/operators';
import {DatePipe} from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import ApiObject from '../../../common/services/state/api-object.service';
import {AbstractUser} from './abstract.user';
import {environment} from '../../../../environments/environment';

const env = environment;
@Injectable()
export class Conversation extends ApiObject {

  constructor(protected http?: HttpClient) {
    super(http);
  }

  // conversationData.type == 'quote_inquiry' ? 'Quote Inquiry' :
  public getTitle() {
    const data = this.getData();
    return data.type === 'quote_inquiry' ? 'Quote Inquiry' : this.getClientName();
  }

  public getSubTitle() {
    const data = this.getData();
    return data.type === 'quote_inquiry' ? this.getClientName() : 'FF-' + (data.order ? data.order.order_id : '');
  }

  public getClientName() {
    const data = this.getData();
    return data.client ? (data.client.first_name + ' ' + data.client.last_name) : '';
  }

  public isUnread() {
    const data = this.getData();
    return data.lastMessage ? data.lastMessage.unread : false;
  }

  public isClosed() {
    const data = this.getData();
    return data.pilot_closed || data.client_closed;
  }

  public getTextPreview() {
    const data = this.getData();
    if (data.messages && data.messages.length > 0) {
      return data.messages[0].body.slice(0, 20);
    } else {
      return '';
    }
  }

  public getShortTime() {
    const data = this.getData();
    if (!(data.messages && data.messages.length > 0)) {
      return '';
    }
    const d = new Date(data.messages[0].created_at);
    const ONE_DAY = 60 * 60 * 1000 * 24;
    let format = 'EEE';
    if (((new Date).getTime() - d.getTime()) < ONE_DAY) {
      format = 'shortTime';
    }
    return (new DatePipe('en').transform(d, format));
  }

  public markAsUnread() {
    const url = this.resourceUrl + '/mark-unread';
    this.http.post(url, null)
      .subscribe(() => {
        this.pull();
      });
  }

  public markAsRead() {
    const url = this.resourceUrl + '/mark-read';
    this.http.post(url, null)
      .subscribe(() => {
        this.pull();
      });
  }

  public postMessage(text: string, user: AbstractUser) {
    const data = this.getData();
    if (!data.messages) {
      data.messages = [];
    }
    const ob = this.http.post(env.apiBaseUrl + env.endpoints[user.getUserType()]['conversations'] + '/' + data.id, {
      'body': text
    }).subscribe((res) => {
      data.messages.push({
        is_mine: true,
        created_at: (new Date()).toUTCString(),
        body: text
      });
      this.stream.next({
        type: 'CHANGE',
        data: data
      });
    });
  }

  public getMessages(): Observable<any[]> {
    return this.http.get(this.resourceUrl + '?expand=messages').pipe(
    map((x) => {
      return x ? x['messages'] : [];
    }));
  }

  public end() {
    const url = this.resourceUrl + '/close';
    this.http.post(url, null)
      .subscribe(() => {
        this.pull();
      });
  }

  public searchable(): string {
    const data = this.getData();
    return [data.client.first_name, data.client.last_name, data.order.order_id].join(' ').toLowerCase();
  }

}
