import {Component, Inject, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {finalize, map} from 'rxjs/operators';

import {isMobile} from '../../../common/services/util';
import {JobListInterface} from '../../../common/interfaces/service';
import {LoadingComponent} from '../../../common/components/loading/loading.component';

@Component({
  selector: 'base-job-list',
  template: ''
})
export class JobListComponent {

  public isMobile: Function = isMobile;
  public jobList: Array<any> = [];
  protected functions: any = {
    previous: 'getPreviousJobs',
    quotes: 'getQuoteRequests',
    active: 'getActiveJobs'
  };

  @ViewChild(LoadingComponent, { static: false }) loading: LoadingComponent;

  constructor(@Inject('JobListInterface') protected service: JobListInterface,
      protected router: Router) {
  }

  protected retrieveList(fn: string = this.functions.active): void {
    this.loading.toggle();
    this.service[fn]()
    .pipe(map(x => x), finalize(() => {
      this.loading.toggle();
    })).subscribe(list => {
      this.jobList = list;
    });
  }

  public isEmpty(): boolean {
    return this.jobList.length === 0;
  }

  public toOverview(activity: any, context): void {
    if (this.isMobile()) {
      this.router.navigate(['/' + context + '/jobs/details', activity.order_id, 'overview']);
    }
  }

  public viewSummary(activity: any, context): void {
    if (this.isMobile()) {
      this.router.navigate(['/' + context + '/jobs/summary', activity.order_id, 'view']);
    }
  }
}
