
import {finalize} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {Component, OnInit, Inject, ViewChild} from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {Location} from '@angular/common';

import {AccountInterface} from '../../../../common/interfaces/service';
import {environment} from '../../../../../environments/environment';
import {LoadingComponent} from '../../../../common/components/loading/loading.component';
import {ParamHandlerComponent} from '../../abstract/param.handler.component';
import {Validator} from '../../../../common/services/validator';

@Component({
  selector: 'base-password-change',
  template: ''
})
export class BasePasswordChangeComponent extends ParamHandlerComponent implements OnInit {

  private validationParams: any = environment.apiValidationParams;
  protected submitted = false;
  public done = false;
  protected serverError: string;
  protected form: FormGroup;
  private token: string;
  public meta: any = {};
  public tokenValid = false;

  @ViewChild(LoadingComponent, { static: false }) loading: LoadingComponent;

  constructor(@Inject('AccountInterface') protected service: AccountInterface,
  protected route: ActivatedRoute,
  protected location: Location,
  protected router: Router) {
    super(route, location, router);
    this.params.subscribe(params => {
      if (params['token']) {
        this.token = params['token'];
        const timestamp = Number(this.token.substr(this.token.lastIndexOf('_') + 1));
        const expire = 3600; // 1 hour
        const now = Math.floor(new Date().getTime() / 1000.0);
        this.tokenValid = timestamp + expire >= now;
      } else {
        this.toNotFound();
      }
    });
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.minLength(this.validationParams.password.minlength)]),
      passwordConfirm: new FormControl('')
    });
    this.form.get('passwordConfirm').setValidators(
      [
        Validators.required,
        Validators.minLength(this.validationParams.password.minlength),
        Validator.matchTo.bind(this.form.get('password'))
      ]
    );
  }

  private onSubmit(): void {
    if (this.serverError) {
      this.serverError = null;
    }
    this.submitted = true;
    if (this.form.valid) {
      this.loading.toggle();
      const payload = Object.assign(this.form.getRawValue(), {token: this.token});
      delete payload.passwordConfirm;
      this.service.changePasswordWithToken(payload).pipe(finalize(() => {
        this.loading.toggle();
      })).subscribe(res => {
        this.done = true;
      }, e => {
        this.serverError = e;
      });
    }
  }
}
