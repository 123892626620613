import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

import {AccountInterface} from '../../interfaces/service';
import {Broadcast} from '../../../common/services/broadcast';
import {environment} from '../../../../environments/environment';
import {SaveButton} from '../../../common/services/savebutton';

@Component({
  selector: 'base-billing',
  template: ''
})
export class BaseBillingComponent implements OnInit {

  public commonValidators: Array<any> = [Validators.maxLength(environment.apiValidationParams.strMaxlength)];
  public button: SaveButton = new SaveButton();
  public initProfile = false;
  public submitted = false;

  public compensationFields: Array<any> = [
    {
      key: 'rate1',
      validators: [].concat(this.commonValidators)
    },
    {
      key: 'rate2',
      validators: [].concat(this.commonValidators)
    },
    {
      key: 'rate3',
      validators: [].concat(this.commonValidators)
    },
    {
      key: 'currency',
      validators: [].concat(this.commonValidators)
    },
    {
      key: 'travel_distance',
      validators: [].concat(this.commonValidators)
    },
    {
      key: 'travel_distance_checker',
      validators: [].concat(this.commonValidators)
    }
  ];

  public addressFields: Array<any> = [
    {
      key: 'first_name',
      validators: [Validators.required].concat(this.commonValidators)
    },
    {
      key: 'last_name',
      validators: [Validators.required].concat(this.commonValidators)
    },
    {
      key: 'billing_business_name',
      validators: [Validators.required].concat(this.commonValidators)
    },
    {
      key: 'billing_address_line1',
      validators: [Validators.required].concat(this.commonValidators)
    },
    {
      key: 'billing_address_line2',
      validators: [].concat(this.commonValidators)
    },
    {
      key: 'billing_country',
      validators: [Validators.required].concat(this.commonValidators)//
    },
    {
      key: 'billing_city',
      validators: [Validators.required].concat(this.commonValidators)//
    },
    {
      key: 'billing_zip',
      validators: [Validators.required].concat(this.commonValidators)//
    }
  ];

  public otherFields: Array<any> = [
    {
      key: 'small_business',
      validators: [].concat(this.commonValidators)
    },
    {
      key: 'billing_entity_type',
      validators: [Validators.required].concat(this.commonValidators)
    }
  ];

  public bankFields: Array<any> = [
    {
      key: 'billing_tax_id',
      validators: [Validators.required].concat(this.commonValidators)
    }
  ];

  public billingForm: FormGroup = new FormGroup({});

  constructor(@Inject('AccountInterface') protected service: AccountInterface, protected broadcast: Broadcast) {
    this.button.broadcaster(broadcast);
  }

  ngOnInit(): void {
    this.service.getDataWhenReady().subscribe(profile => {
      this.initForms(profile);
    });
  }

  public getInvoicingFields(): string[] {
    return this.addressFields.concat(this.bankFields);
  }

  public onEntityChange(): void {
    this.submitted = false;
  }

  protected initForms(profile: any): void {
    this.createFormControls(this.compensationFields, this.billingForm, 'compensations', profile);
    this.createFormControls(this.getInvoicingFields().concat(this.otherFields), this.billingForm, 'invoicing', profile);
    this.setDelay();
  }

  protected createFormControls(fields: Array<any>, form: FormGroup, key: string, values: any) {
    const controls = {};
    fields.forEach(f => {
      controls[f.key] = new FormControl(values[f.key], f.validators);
    });
    form.controls[key] = new FormGroup(controls);
  }

  protected setDelay(): void {
    setTimeout(done => {
      this.initProfile = true;
    }, 100);
  }

  protected save(): void {
    this.submitted = true;
    let valid = true;
    for (const group of Object.keys(this.billingForm.controls)) {
      if (!this.billingForm.get(group).valid) {
        valid = false;
        break;
      }
    }
    if (valid) {
      this.button.turnToRequest();
      const data = this.billingForm.getRawValue();
      const payload = Object.assign({}, data['compensations'], data['invoicing']);
      this.service.save(payload).subscribe(res => {
        this.button.receiveResponse();
      }, err => {
        this.button.receiveResponse(true);
      });
    }
  }

}
